import http from '../js/http'



//获得投票信息
export function getSurveyInfo(id) {
    return http.get(`/survey/getSurveyInfo/${id}`)
}


//获得投票信息
export function checkSurveyLimit(id) {
    return http.get(`/survey/checkSurveyLimit/${id}`)
}

//提交表单
export function submitSurvey(params) {
    return http.post(`/survey/submitSurvey`, params)
}



