<template>
    <div id="realname" class="login">
        <van-popup class="vanPopup" style="z-index: 9" width="375" v-model="show"  :close-on-click-overlay="false">
            <p class="dengRu">实名</p>
            <img class="close" @click="close()" src="../assets/images/VoteTemp-1/close.png" alt="">
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                     class="demo-ruleForm">
                <div class="qrCode">
                    <img class="code" src="../assets/images/VoteTemp-1/code.png" alt="">
                    <el-form-item label="" prop="realName">
                        <el-input v-model="ruleForm.realName" type="text" placeholder="请输入名字"
                                  maxlength="4" clearable></el-input>
                    </el-form-item>
                </div>
                <el-button style="background-image: none;" class="submits" type="primary"
                           @click="submitForm('ruleForm')">{{loginText}}
                </el-button>
            </el-form>
        </van-popup>
    </div>
</template>

<script>
import {Toast} from 'vant'
import {qrCode, loginPhone} from '../assets/api/vote'
import {bindingRealName} from "../assets/api/user";

export default {
    name: "realname",
    data() {
        return {
            show: true,
            phone: '',
            code: '',
            time: 120,
            timer: null,
            timeFlag: true,
            ruleForm: {
                // phone: "",
                realName: "",
            },
            rules: {
                // phone: [
                //     {
                //         pattern: /^1[3-9](\d{9})$/,
                //         message: "请输入正确的手机号",
                //         trigger: ["blur"],
                //     },
                //     {max: 11, message: "请输入11位手机号", trigger: "change"},
                //     {required: true, message: "请输入手机号", trigger: "blur"},
                // ],
                code: [{required: true, message: "请输入名字", trigger: "blur"}],
            },
            loginText: "提交",
            loginLoading: false,
        }
    },
    created() {},
    methods: {
        close(){
            this.show = false
        },
        // 手机号登录成为壹点用户并获取信息
        submitForm(formName) {
            // 授权并登录 先做非空验证
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.loginText = "正在登录...";
                    this.loginLoading = true;
                    let obj = {
                        // mobile: this.ruleForm.phone.toString().trim(),
                        realName: this.ruleForm.realName.toString().trim(),
                        // mobile: '20000045678',
                        // smsCode: '133333',
                    };
                    let {code, msg} = await bindingRealName(obj)
                    if(code === 200) {
                        this.$emit('getRealName', obj.realName)
                    }else {
                        this.$message.error(msg)
                    }
                } else {
                    return false;
                }
            });
        },
    },
    computed: {
        theme(){
            return this.$store.getters.getTheme
        },
        token(){
            return this.$store.getters.getToken
        }
    },
}
</script>

<style scoped lang="less">
@import "../assets/css/theme";
.module-theme(@theme, @tag) {

    .close{
        width: 22px;
        height: 22px;
        position: absolute;
        right: 15px;
        top: 15px;
    }
    .submits {
        width: calc(100% - 100px) !important;
        height: 50px!important;
        margin: 40px 50px 0 !important;
        border-radius: 25px!important;
        font-size: 16px!important;
        color: #FFFFFF;
        text-align: center;
        background-image: none;
        display: flex;
        justify-content: center;
        border: none !important;
        outline: none !important;
        line-height: 27px !important;
    }
    .dengRu{
        font-size: 20px;
        color: #333333;
        line-height: 28px;
        text-align: center;
        margin: 40px 0 20px;
    }
    .van-overlay {
        z-index: 9 !important;
    }
    .van-popup {
        width: 355px;
        height: 330px;
        z-index: 2009 !important;
        margin: auto;
        border-radius: 6px;
    }
    .phone,.code{
        width: 24px;
        height: 24px;
        display: block;
        margin-top: 10px;
        margin-right: 10px;
    }
}
</style>
<style lang="less">
.qrCode {
    width: calc(100% - 60px);
    height: 48px;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    /*border-bottom: 1px solid #999;*/
    border-bottom: 1px solid #F5F5F5;
}

.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    content: '*';
    color: rgba(1, 1, 1, 0);
    margin-right: 4px;
}

.el-form-item__label {
    width: 80px !important;
    line-height: 48px;
    text-align: left;
}

.el-form-item__content {
    margin-left: 0px !important;
}

.el-form {
    margin-top: 10px;
}

.el-form-item {
    /*width: calc(100% - 15px);*/
    height: 38px;
    margin: 0 ;
}
.el-input__inner{
    outline: none!important;
    border: none!important;
    box-sizing: border-box!important;
}

.el-input {
    width: 130px !important;
    border: none !important;
    height: 48px !important;
    line-height: 48px !important;
    text-align: left;
}

.el-input__suffix {
    display: none;
}

.el-input--suffix .el-input__inner {
    padding-right: 0 !important;
}

.el-form-item__error {
    z-index: 999;
    left: 15px;
    top: 75%;
}
.el-button--primary{
    background-color: #CB0709!important;
}

.el-button {
    height: 28px !important;
    margin-top: 14px !important;
    width: 74px !important;
    background-image: none;
    display: flex;
    justify-content: center;
    border: none !important;
    outline: none !important;
    border-radius: 14px !important;
    font-size: 14px;
    line-height: 5px !important;
    margin-left: 26px!important;
}
</style>
