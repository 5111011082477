<template>
    <div id="Form">
        <nav>
            <div @click="goBack">
                <img src="../../../assets/images/Answer/arrow.png" alt="">
            </div>
            <div>信息</div>
            <div></div>
        </nav>
        <img class="banner" src="../../../assets/images/Answer/rank.png" alt="">
        <div class="input-box">
            <van-form>
                <div class="input-item"
                     v-for="(item, index) of list"
                     :key="index">
                    <div>
                        <span v-show="item.isNecessary === '1'" style="color: rgba(229, 29, 53, 1)">*</span>
<!--                        {{ item.isNecessary === '1' ? '*' : ''}}-->
                        <span>{{ item.questContent }}</span>
<!--                        {{item.questContent}}-->
                    </div>
                    <van-field
                            v-model="item.answer"
                            :placeholder="item.questContent"
                            v-if="item.questType == 1"
                    />

                    <el-input
                            v-if='item.questType == 2'
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="item.answer">
                    </el-input>
                    <van-radio-group v-model="item.answer" v-if='item.questType == 4'>
                        <van-radio v-for="(radio, index) of item.actSurveyQuestOptionList"
                                   :key="index"
                                   :name="radio"
                                   checked-color="#ee0a24">
                            {{ radio.optionContent }}
                        </van-radio>
                        <!--                            <van-radio name="2" checked-color="#ee0a24">单选框 2</van-radio>-->
                    </van-radio-group>
                    <van-uploader
                            v-model="preImg"
                            :max-count="1"
                            v-if='item.questType == 5'
                            :after-read="(file) => afterRead(file, item)"
                            multiple/>
                    <van-checkbox-group v-model="item.answer" v-if='item.questType == 9'>
                        <van-checkbox
                                v-for="(check, index) of item.actSurveyQuestOptionList"
                                :name="check"
                                shape="square">{{ check.optionContent }}
                        </van-checkbox>
                    </van-checkbox-group>
                </div>
            </van-form>
            <div class="bottom-info">
                <div class="save-btn" @click="deSubmit">提交</div>
                <div>注：
                    <span>*</span>
                    号为必填项
                </div>
            </div>

        </div>
<!--        <div class="bottom-btn">-->
<!--            <div @click="submit">提交</div>-->
<!--        </div>-->
    </div>
</template>

<script>
import {getAttachFormQuests, saveAttachFormFill} from '../../../assets/api/user'
import {submitSurvey} from "../../../assets/api/questionnaire";
import lodash from "lodash";

export default {
    name: "Form",
    data() {
        return {
            id: '',
            list: []
        }
    },
    created() {
        this.getParams()
        this.getAttachFormQuests()
    },
    methods: {
        getParams() {
            this.id = this.$route.query.id
        },
        async getAttachFormQuests() {
            let {code, data} = await getAttachFormQuests({
                actId: this.id,
                actType: '4'
            })
            if (code === 200) {
                this.list = data
            }
        },
        goBack() {
            this.$router.back()
        },
        deSubmit: lodash.debounce(function () {
            this.submit()
        }, 200),
        async submit() {
            let flag = true
            let requestFlag = false
            let submitQuestList = []
            console.log(this.list)
            for (let item of this.list){
                if(item.isNecessary === '1') {
                    console.log(item)
                    if(!item.answer) {
                        flag = false
                    }
                }
                if(item.answer) {
                    requestFlag = true
                }
            }
            if(!flag) {
                this.$message.error('请填写必填选项')
                return false
            }
            submitQuestList = this.list.map(item => {
                let temData = null

                if(item.questType == 4 || item.questType == 9) {
                    let arr = Array.isArray(item.answer) ? item.answer : [item.answer]
                    temData = {
                        questId: item.questId,
                        submitQuestOptionList: arr.map(box => box.optionId)
                    }
                }else {
                    temData = {
                        attachQuestId: item.questId,
                        attachText: item.answer
                    }
                }
                return temData
            })

            let params = {
                actId: this.id,
                actType: '4',
                actAttachFillOptions: submitQuestList
            }
            console.log(params)
            if(!requestFlag) {
                console.log('没请求')
                this.$router.replace({
                    path: '/answers1-details',
                    query: {
                        id: this.id
                    }
                })
                return  false
            }
            let {code, data, msg} = await saveAttachFormFill(params)
            if(code == 200) {
                this.$message.success('提交成功')
                this.$router.replace({
                    path: '/answers1-details',
                    query: {
                        id: this.id
                    }
                })
            }else {
                msg && this.$message.error(msg)
            }
        }
    }
}
</script>

<style scoped lang="less">

#Form {
    width: 375px;
    margin: 0 auto;
    //padding-bottom: 70px;

    nav {
        width: 375px;
        height: 45px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;

        > div {
            width: 15%;
            text-align: center;
        }
    }

    .banner {
        width: 375px;
    }

    .input-box {
        width: 95%;
        min-height: 400px;
        margin: 10px auto 0;
        background: white;
        padding-top: 10px;
        position: relative;
        padding-bottom: 85px;

        .input-title {
            width: 130px;
        }

        .input-item {
            padding: 10px 16px;

            span {
                font-size: 16px;
                font-weight: bold;
                margin-right: 4px;
            }

            > div {
                text-align: left;
                margin-bottom: 10px;
            }
        }
    }

    .bottom-btn {
        width: 375px;
        height: 60px;
        position: fixed;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, -0);
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;

        > div {
            border-radius: 50px;

            max-width: 375px;
            //background: @theme;
            background: rgb(249,143,103);
            color: white;
            width: 90%;
            height: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .disable-btn {
            background: #999999;
        }
    }


    .bottom-info {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;

        .save-btn {
            width: 320px;
            height: 35px;
            border-radius: 50px;
            background: rgb(232,129,60);
            color: white;
            text-align: center;
            line-height: 35px;
        }

        > div:nth-of-type(2) {
            font-size: 12px;
            color: rgba(153, 153, 153, 1);

            > span {
                display: inline-block;
                margin: 10px auto 38px;
                color: rgba(229, 29, 53, 1)
            }
        }
    }

}
</style>