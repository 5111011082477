import http from '../js/http'
import requested from '../../../utils/axiosed'
// import requestQn from '@/plugins/qn'

// export function getVoteInfoList(params) {
//     return http.get('/vote/getVoteInfo', params)
//     // return http.get('/queryAllChannelBaseInfoList.do?timestamp=1639014758319&tokenID=4D14AD0732C492925D4765ABD20A33D5')
// }

// 获取七牛token
// export function QNToken () {
//     return requestQn({
//         url: '/getQiniuUptoken.do',
//         method: 'post',
//     })
// }

// 顶象验证
// export function qrCode (data) {
//     return requested({
//         url: '/auth/sms/v1/code',
//         method: 'post',
//         data: data,
//     })
// }

export function qrCode(data) {
    return http.post('/activity/getSmsCode', data)
    // return http.get('/queryAllChannelBaseInfoList.do?timestamp=1639014758319&tokenID=4D14AD0732C492925D4765ABD20A33D5')
}


// 手机号验证码登录
export function loginPhone (data) {
    return requested({
        url: '/auth/sms/login',
        method: 'post',
        data: data,
        headers: {
            // "Content-Type": "application/json; charset=UTF-8",
            "Content-Type": "text/plain",
            version: "10.0.0",
            client_imei: "pc",
            client_type: "3",
            appName: "otherplatform",
            Authorization: "Basic cWx5ZC1hcHA6cWx5ZC1hcHA=",
        },
    })
}
// 手机号验证码登录
// export function userMes (data,option) {
//     return requested({
//         url: '/user/v1/identity',
//         method: 'post',
//         data: data,
//         headers: {
//             'Content-Type': "application/json; charset=UTF-8",
//             'token':option.token,
//         },
//     })
// }
//获得投票信息
export function getVoteInfo(params) {
    return http.get('/vote/getVoteInfo', params)
    // return http.get('/queryAllChannelBaseInfoList.do?timestamp=1639014758319&tokenID=4D14AD0732C492925D4765ABD20A33D5')
}
//获得投票的选项信息
export function getVoteOptionInfo(params) {
    return http.get('/vote/getVoteOptionInfoList', params)
    // return http.get('/queryAllChannelBaseInfoList.do?timestamp=1639014758319&tokenID=4D14AD0732C492925D4765ABD20A33D5')
}
// 活动评选数据
export function getVoteStatistInfo(params) {
    return http.get('/vote/getVoteStatistInfo', params)
    // return http.get('/queryAllChannelBaseInfoList.do?timestamp=1639014758319&tokenID=4D14AD0732C492925D4765ABD20A33D5')
}
// 用户登录后调用这个接口返回用户是否还能投票
export function checkIfCanVote(params) {
    return http.get('/vote/checkIfCanVote', params)
    // return http.get('/queryAllChannelBaseInfoList.do?timestamp=1639014758319&tokenID=4D14AD0732C492925D4765ABD20A33D5')
}
// 投票
export function operateVote(params) {
    return http.post('/vote/operateVote', params)
    // return http.get('/queryAllChannelBaseInfoList.do?timestamp=1639014758319&tokenID=4D14AD0732C492925D4765ABD20A33D5')
}
// 排行榜
export function getVoteRankList(params) {
    return http.get('/vote/getVoteRankList', params)
    // return http.get('/queryAllChannelBaseInfoList.do?timestamp=1639014758319&tokenID=4D14AD0732C492925D4765ABD20A33D5')
}

export function getVoteInfoLists(params) {
    return http.post('/vote/voteInfoList', params)
}

//获得投票的选项信息
export function getVoteOptionDetails(id) {
    return http.get(`/vote/getVoteOptionInfo/${id}`)
}






