// import
//     JSEncrypt
//     from
//         'jsencrypt'
import JSEncrypt from './jsencrypt'

// const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDfV6XQsNF0YjCywuAiRh/iA87CMgCuCNbgsQzSQLremRdMxiVRKy/yiOYJB7jVT3l945lNRHwHTjMotiwp1o6K7KxC109dTyXmYcf59v6J2TpKe5VECN7Hgr4RRXxoLyL/rpYm2LAbYjqD6ht7iO/54KhuanDcH2B7UUBxK9T3SQIDAQAB";
const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCvT0vHJn/iebE3GIjng5efC67tZrRmBBMB1Y4RlLwP40kgtOh1LWTwp0aPlzuqBDFa2r1tq5AFl7TY1Ve3puAZhyuBIyF74uqlgXTysUtwdKysvD0AwPJyU6tKIYvfXIfaZjqdy+0A743G2gscOHoSTfGj9hD/0mjEWbqo6mXblQIDAQAB"
export default {

// 加密
  encrypt(str, from) {
    // 公钥Base64编码:
    let crypt = new JSEncrypt(); // 默认密钥字节长度
    crypt.setPublicKey(publicKey); // 公钥加密
    // let enStr = crypt.encrypt(str);
    let enStr = crypt.encryptLong2(str)
    return enStr
  },

// 解密
  decrypt(str) {
    let privateKey = '';
    let crypt = new JSEncrypt();
    crypt.setPrivateKey(privateKey);// 私钥解密
    // let enStr = crypt.decrypt(str);
    let enStr = crypt.decryptLong2(str);
    return enStr
  },

  generatekey(num) {
    let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let key = "";
    for (let i = 0; i < num; i++) {
      let randomPoz = Math.floor(Math.random() * library.length);
      key += library.substring(randomPoz, randomPoz + 1);
    }
    return key;
  },

// AES加密
//   crypto(ivStrs) {
//     let keyStr = generatekey(16);// 密钥
//     const key = CryptoJS.enc.Utf8.parse(keyStr);  // 十六位十六进制数作为密钥  //十六位十六进制数作为密钥偏移量
//     let srcs = CryptoJS.enc.Utf8.parse(ivStrs);//
//     let encrypted = CryptoJS.AES.encrypt(srcs, key, {
//       iv: key,
//       mode: CryptoJS.mode.CBC,
//       padding: CryptoJS.pad.Pkcs7
//     });
//     let appKey = encrypted.toString();//加密后的信息
//     return appKey
//   },
}
// export
// {
//     encrypt,
//     decrypt,
//     crypto,
// }




