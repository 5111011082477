<template>

</template>

<script>
import {qlydlogout} from '../../assets/api/user'

export default {
    name: "Logout",
    created() {
        this.logout()
    },
    methods: {
         async logout() {
             let token = localStorage.getItem('token')
             if(token) {
                 let data = await qlydlogout({
                     token
                 })
                 alert(JSON.stringify(data))
             }else {
                 alert('无token')
             }
             this.$store.commit('CLEAR_TOKEN')


         }
    }
}
</script>

<style scoped>

</style>