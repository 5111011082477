<template>
    <div id="ScrapingCard">
        <vue-scratchable :getPercentageCleared="true"
                         ref="scratchable"
                         :brushOptions="brush"
                         @percentage-update="updatePoints">
            <div class="draw-area" v-show="show">
                <img src="../../../assets/images/Draw/temp-btn.png" @click="startDraw">
            </div>
            <div class="gif-box">{{gif.prizeName || '谢谢参与'}}</div>
        </vue-scratchable>



<!--        <vue-scratchable :getPercentageCleared="false"-->
<!--                         :brushOptions="brush"-->
<!--                         :hideOptions="hide"-->
<!--                         @percentageUpdate="updatePoints">-->
<!--            <div class="gif-box">{{gif || '谢谢参与'}}</div>-->
<!--        </vue-scratchable>-->



<!--        <vue-scratchable :brushOptions="brush"-->
<!--                         :getPercentageCleared="true"-->
<!--                         @percentage-update="updatePoints">-->
<!--            <div>{{gif || '谢谢参与'}}</div>-->
<!--        </vue-scratchable >-->
    </div>
</template>

<script>
import VueScratchable from 'vue-scratchable';
import loadsh from 'lodash'
import paperPattern from '../../../assets/images/Draw/temp-btn.png';
import lodash from "lodash";

export default {
    name: "ScrapingCard",
    components: {
        VueScratchable
    },
    data(){
        return{
            show: true,
            gif: '',
            hide: {
                type: 'pattern',
                src: paperPattern,
                repeat: 'repeat',
            },
            brush: {
                size: 60,
                shape: 'round',
            }
        }
    },
    methods: {
        startDraw: lodash.debounce(function () {

            this.$emit('startDraw')
        }, 500),
        // startDraw() {
        //     this.$emit('startDraw')
        // },
        sendGif(gif) {
            console.log(gif)
            this.gif = gif
            this.show = false
        },
        updatePoints(percentage) {
            console.log(percentage)
            if(percentage > 40) {
                (loadsh.throttle( () => {
                    console.log(this)
                    this.$emit('getScrapingCardGif')
                }, 500))()
            }

        },
        init(){
            this.show = true
            this.$refs.scratchable.init()
        }
    }
}
</script>

<style scoped lang="less">
#ScrapingCard {
    width: 330px;
    height: 130px;
    margin: 0 auto;

    //position: relative;

    .draw-area {
        width: 330px;
        height: 130px;
        background: url("../../../assets/images/Draw/temp1-draw-bg.png");
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 200;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        > img {
            width: 117px;
            height: 35px;
        }
    }

    .gif-box {
        margin: 0 auto;
        width: 330px;
        height: 130px;
        background: url("../../../assets/images/Draw/temp1-draw-bg.png");
        background-size: 100% 100%;
        color: white;
        font-size: 20px;
        text-align: center;
        line-height: 130px;
    }

}
</style>