<template>
    <div id="Nine">
        <LuckyGrid
                ref="myLucky"
                width="300px"
                height="300px"
                :prizes="prizes"
                :blocks="blocks"
                :buttons="buttons"
                :defaultConfig="defaultConfig"
                @start="startCallback"
                @end="endCallback"
        />
    </div>
</template>

<script>
// 修改部分
export default {
    name: "Nine",
    props:{
        prizeList: {
            type: Array,
            default: []
        }
    },
    data () {
        return {
            blocks: [
                { padding: '10px', background: '#869cfa' },
                { padding: '10px', background: '#e9e8fe' },
            ],
            prizes: [],
            defaultConfig: {
                background: '#b8c5f2'
            },
            buttons: [
                {
                    x: 1, y: 1,
                    background: 'rgba(0,0,0,0)',
                    imgs: [{
                        src: 'https://img.qiluyidian.net/5JKFcG_1667295955990.png?imageslim',
                        width: '100%',
                        height: '100%',
                    }],
                },
            ],
        }
    },
    created() {
        // this.$nextTick(() => {
        //     this.startCallback(0)
        // })
        this.prizeListHandle(this.prizeList)
    },
    methods: {
        prizeListHandle(list) {
            console.log(list)
            // { x: 0, y: 0, fonts: [{ text: '0', top: '25%' }] },
            // { x: 1, y: 0, fonts: [{ text: '1', top: '25%' }] },
            // { x: 2, y: 0, fonts: [{ text: '2', top: '25%' }] },
            // { x: 2, y: 1, fonts: [{ text: '3', top: '25%' }] },
            // { x: 2, y: 2, fonts: [{ text: '4', top: '25%' }] },
            // { x: 1, y: 2, fonts: [{ text: '5', top: '25%' }] },
            // { x: 0, y: 2, fonts: [{ text: '6', top: '25%' }] },
            // { x: 0, y: 1, fonts: [{ text: '7', top: '25%' }] },
            let posArr = [
                {x: 0, y: 0},
                {x: 1, y: 0},
                {x: 2, y: 0},
                {x: 2, y: 1},
                {x: 2, y: 2},
                {x: 1, y: 2},
                {x: 0, y: 2},
                {x: 0, y: 1},
            ]
            this.prizes = list.map((item, index) => {
                return {
                    ...posArr[index],
                    ...item,
                    background: '#b8c5f2',
                    imgs: [
                        {
                            src: item.prizeImg || 'https://img.qiluyidian.net/EGZBBm_1667296366916.png?imageslim',
                            width: '60%',
                            top: '20%'
                        }
                    ]
                }
            })
            console.log(this.prizes)
        },
        // 点击抽奖按钮会触发star回调
        startCallback () {
            // 调用抽奖组件的play方法开始游戏
            this.$emit('startDraw')
            // 模拟调用接口异步抽奖
            // setTimeout(() => {
            //     // 假设后端返回的中奖索引是0
            //     const index = 0
            //     // 调用stop停止旋转并传递中奖索引
            //     this.$refs.myLucky.stop(index)
            // }, 3000)
        },
        // 抽奖结束会触发end回调
        endCallback (prize) {
            console.log('emd')
            this.$emit('getScrapingCardGif')
        },
        sendGif(gif) {
            this.$refs.myLucky.play()

            console.log(this.prizes)
            const index = gif ? this.prizes.findIndex(item => item.prizeId === gif.prizeId) : 0
            // const index = this.prizes.findIndex(item => item.prizeId === gif.prizeId)
            console.log(gif)
            console.log(index)
            setTimeout(() => {
                this.$refs.myLucky.stop(index)
            }, 3000)

        },
    }
}
</script>

<style scoped lang="less">

#Nine {

    > #lucky {
        margin: 0 auto;
        width: 345px;
        height: 345px;

    }

}

.add {
    font-size: 16px;
}


</style>