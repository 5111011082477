<template>
    <div id="EggGame">
        <div class="box">
            <ul class="egg clearfix">
                <li v-for="(item, index) of stepImage">
                    <img :src="item.step === 1 ? gif1 : gif2"
                         class="goldegg init"
                         :key="index"
                         @click="able && hammer($event, item)">
                    <!--                    <img src="../../../assets/images/Draw/base.png">-->
                    <div class="info"></div>
                </li>
<!--                <li>-->
<!--                    <img src="../../../assets/images/Draw/egg.png" class="goldegg init jump" @click="hammer($event)">-->
<!--                    <img src="../../../assets/images/Draw/base.png">-->
<!--                    <div class="info"></div>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <img src="../../../assets/images/Draw/egg.png" class="goldegg init" @click="hammer($event)">-->
<!--                    <img src="../../../assets/images/Draw/base.png">-->
<!--                    <div class="info"></div>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <img src="../../../assets/images/Draw/egg.png" class="goldegg init"  @click="hammer($event)">-->
<!--                    <img src="../../../assets/images/Draw/base.png">-->
<!--                    <div class="info"></div>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <img src="../../../assets/images/Draw/egg.png" class="goldegg init"  @click="hammer($event)">-->
<!--                    <img src="../../../assets/images/Draw/base.png">-->
<!--                    <div class="info"></div>-->
<!--                </li>-->
            </ul>
<!--            <div id="hammer" class="shak" :style="`${x && `left: ${x}px`}; ${y && `top: ${y}px`}`"></div>-->
        </div>
    </div>
</template>

<script>
import lodash from 'lodash'
export default {
    name: "EggGame",
    data(){
        return {
            x: '',
            y: '',
            stepImage:[
                {step: 1},
                {step: 1},
                {step: 1}
            ],
            gif1: require(`../../../assets/images/Draw/egg1.gif`),
            gif2: require(`../../../assets/images/Draw/egg2.gif`),
            able: true,
            stepObject: {}
        }
    },
    methods: {
        hammer: lodash.debounce(function (event, item) {
            this.able = false
            this.stepObject = item
            this.$emit('startDraw')
        }, 500),
        // hammer(event, item) {
        //     // let parentBox = document.getElementsByClassName('egg')[0]
        //     // let {left, top} = event.target.getBoundingClientRect()
        //     // console.log(event)
        //     // console.log(parentBox.getBoundingClientRect())
        //     // console.log(left)
        //     // this.x = left - parentBox.getBoundingClientRect().left + 20
        //     // this.y = top - parentBox.getBoundingClientRect().top
        //     // console.log(event.target.getBoundingClientRect())
        //     this.able = false
        //     this.stepObject = item
        //     this.$emit('startDraw')
        //     // setTimeout(() => {
        //     //     this.$emit('startDraw')
        //     // }, 3200)
        //
        //     // this.$emit('startDraw')
        // },
        sendGif(gif) {
            this.stepObject.step ++
            setTimeout(() => {
                this.$emit('getScrapingCardGif')
            }, 3200)

        },
        init(){
            console.log(111)
            this.stepObject = {}
            this.able = true
            this.stepImage.map(item => {
                item.step = 1
            })
            // this.x = ''
            // this.y = ''
        }
    }
}
</script>

<style scoped lang="less">
@rem: 75rem;
#EggGame {
    width: 330px;
    height: 180px;
    margin: 0 auto;


    /*砸蛋区域*/

    .box {
        position: relative;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }


    #change {
        margin: 0 5/@rem;
    }

    .egg {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        height: 100%;
        background: rgb(255,242,220);
    }

    .egg li {
        width: 33.3%;
        height: 130px;

        position: relative;
        padding-bottom: 15px;
    }

    .egg li img:nth-of-type(2) {
        width: 70%;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        //height: 100;
    }

    //.egg li img.goldegg {
    //    width: 60%;
    //}

    .egg li img.goldegg {
        top: 25px;
        left: 50%;
        position: absolute;
        width: 100%;
        transform: translateX(-50%);
        z-index: 100;
        //height: 151/@rem;
        -o-transform-origin: center bottom;
        -ms-transform-origin: center bottom;
        -moz-transform-origin: center bottom;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -o-transition: .5s;
        -ms-transition: .5s;
        -moz-transition: .5s;
        -webkit-transition: .5s;
        transition: .5s;
    }

    .egg li img.jump {
        -o-animation: jump .5s infinite alternate;
        -ms-animation: jump .5s infinite alternate;
        -moz-animation: jump .5s infinite alternate;
        -webkit-animation: jump .5s infinite alternate;
        animation: jump .5s infinite alternate;
    }

    @keyframes jump {
        100% {
            top: 15px;
        }
    }

    #info {
        position: absolute;
        left: 40/@rem;;
        top: 115/@rem;;
        width: 75/@rem;;
        height: 40/@rem;;
    }

    #hammer {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 46px;
        background-size: 100% 100%;
        z-index: 200;
        //height: 120/@rem;
        background-image: url("../../../assets/images/Draw/hammer.png");
        -o-transform-origin: right bottom;
        -ms-transform-origin: right bottom;
        -moz-transform-origin: right bottom;
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
        -o-transition: 1s ease-in-out;
        -ms-transition: 1s ease-in-out;
        -moz-transition: 1s ease-in-out;
        -webkit-transition: 1s ease-in-out;
        transition: 1s ease-in-out;
    }

    .shak {
        -o-animation: hammer-move .5s linear infinite alternate;
        -ms-animation: hammer-move .5s linear infinite alternate;
        -moz-animation: hammer-move .5s linear infinite alternate;
        -webkit-animation: hammer-move .5s linear infinite alternate;
        animation: hammer-move .5s linear infinite alternate;
    }

    @keyframes hammer-move {
        100% {
            -o-transform: rotate(-10deg);
            -ms-transform: rotate(-10deg);
            -moz-transform: rotate(-10deg);
            -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
        }
    }

    .hit {
        -o-animation: hammer-hit .2s 3 alternate;
        -ms-animation: hammer-hit .2s 3 alternate;
        -moz-animation: hammer-hit .2s 3 alternate;
        -webkit-animation: hammer-hit .2s 3 alternate;
        animation: hammer-hit .2s 3 alternate;
    }

    @keyframes hammer-hit {
        100% {
            -o-transform: rotate(-20deg);
            -ms-transform: rotate(-20deg);
            -moz-transform: rotate(-20deg);
            -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
        }
    }

    /*规则背景色*/

    #mask-rule .box-rule {
        background-color: #f7b50b;
    }


}
</style>