import JSEncrypt from 'jsencrypt'
const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDjA/i9xoM4QbB4Gi251lUKrhxz06CcpJI4zEGj8f1xsGF5IgzVzgQGBb9quAv826y8r1Bs2aE9r6cszL0wJwmo8VpC2E0VkZYHB1SvwH941DrZtXl89vD4268lhR9vK5gZ1YV52o0iXHaSb5z0pxeQbohY++Ap29G/hGmVpXTXbQIDAQAB "
const jsonKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCvT0vHJn/iebE3GIjng5efC67tZrRmBBMB1Y4RlLwP40kgtOh1LWTwp0aPlzuqBDFa2r1tq5AFl7TY1Ve3puAZhyuBIyF74uqlgXTysUtwdKysvD0AwPJyU6tKIYvfXIfaZjqdy+0A743G2gscOHoSTfGj9hD/0mjEWbqo6mXblQIDAQAB'

//加密
export function rsaPublicData(data) {
    let jsencrypt = new JSEncrypt()
    jsencrypt.setPublicKey(publicKey)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    let result = jsencrypt.encrypt(data)
    // let result1=encodeURIComponent(result)
    return result
}
// encrypt=

export function rsaJsonData(data) {
    let jsencrypt = new JSEncrypt()
    jsencrypt.setPublicKey(publicKey)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    let result = jsencrypt.encrypt(JSON.stringify(data))
    // let result1=encodeURIComponent(result)
    return result
}
//解密
export function rsaPrivateData(data) {
    var jsencrypt = new JSEncrypt()
    jsencrypt.setPrivateKey(privateKey)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    var result = jsencrypt.encrypt(data)
    return result
}
