<template>
    <div id="Nine">
        <LuckyWheel
                ref="myLucky"
                width="326px"
                height="326px"
                :prizes="prizes"
                :blocks="blocks"
                :buttons="buttons"
                :defaultConfig="defaultConfig"
                @start="startCallback"
                @end="endCallback"
        />
    </div>
</template>

<script>
export default {
    name: "Nine",
    props:{
        prizeList: {
            type: Array,
            default: []
        }
    },
    data () {
        return {
            blocks: [{
                padding: '28px',
                background: '#617df2',
                imgs: [{
                    src: 'https://img.qiluyidian.net/R5KATZ_1651042835513.png',
                    width: '100%',
                    height: '100%'
                }]
            }],
            prizes: [],
            // prizes: [
            //     { fonts: [{ text: '', top: '10%' }], background: '#e9e8fe',  imgs: [{ src: 'https://cdn.jsdelivr.net/gh/buuing/cdn/demo/prize.png', width: '40%', top: '10%' }] },
            //     { fonts: [{ text: '', top: '10%' }], background: '#b8c5f2',  imgs: [{ src: 'https://cdn.jsdelivr.net/gh/buuing/cdn/demo/prize.png', width: '40%', top: '10%' }] },
            //     { fonts: [{ text: '', top: '10%' }], background: '#e9e8fe',  imgs: [{ src: 'https://cdn.jsdelivr.net/gh/buuing/cdn/demo/prize.png', width: '40%', top: '10%' }] },
            //     { fonts: [{ text: '3', top: '10%' }], background: '#b8c5f2',  imgs: [{ src: 'https://cdn.jsdelivr.net/gh/buuing/cdn/demo/prize.png', width: '40%', top: '10%' }] },
            //     { fonts: [{ text: '4', top: '10%' }], background: '#e9e8fe' },
            //     { fonts: [{ text: '5', top: '10%' }], background: '#b8c5f2' },
            //     { fonts: [{ text: '3', top: '10%' }], background: '#b8c5f2' },
            //     { fonts: [{ text: '4', top: '10%' }], background: '#e9e8fe' },
            // ],
            defaultConfig: {
                offsetDegree: -(360 / 6 / 2)
            },
            buttons: [
                {
                    radius: '45%',
                    imgs: [{
                        src: 'https://img.qiluyidian.net/CrEeRs_1651042669851.png',
                        width: '100%',
                        top: '-130%'
                    }]
                }
            ],
        }
    },
    mounted() {
        // this.$nextTick(() => {
        //     this.startCallback(0)
        // })
        // this.nextTick(() => {
        //     this.prizeListHandle(this.prizeList)
        // })
        this.prizeListHandle(this.prizeList)

    },
    methods: {
        prizeListHandle(list) {
            let colorAreaList = ['rgb(255,242,215)', 'rgb(255,250,239)']
            this.prizes = list.map((item, index) => {
                return {
                    ...item,
                    fonts: [
                            {
                                text: '',
                                top: '10%'
                            }],
                    background: colorAreaList[index % 2],
                    imgs: [
                            {
                                src: item.prizeImg || 'https://img.qiluyidian.net/6aaBDx_1651128280800.png',
                                width: '40%',
                                top: '10%'
                            }]
                }
            })
        },
        // 点击抽奖按钮会触发star回调
        startCallback () {
            // 调用抽奖组件的play方法开始游戏
            this.$emit('startDraw')
            // 模拟调用接口异步抽奖
            // setTimeout(() => {
            //     // 假设后端返回的中奖索引是0
            //     const index = 0
            //     // 调用stop停止旋转并传递中奖索引
            //     this.$refs.myLucky.stop(index)
            // }, 3000)
        },
        // 抽奖结束会触发end回调
        endCallback (prize) {
            console.log('emd')
            this.$emit('getScrapingCardGif')
        },
        sendGif(gif) {
            this.$refs.myLucky.play()

            console.log(this.prizes)
            const index = gif ? this.prizes.findIndex(item => item.prizeId === gif.prizeId) : 0
            console.log(gif)
            console.log(index)
            this.$refs.myLucky.stop(index)
            // setTimeout(() => {
            //     this.$refs.myLucky.stop(index)
            // }, 3000)
        },
    }
}
</script>

<style scoped lang="less">

#Nine {
    width: 326px;
    height: 326px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    > #lucky {
        margin: 0 auto;
        width: 326px;
        height: 326px;
    }

}


</style>