import http from '../js/http'



//抽奖
export function drawLotteryPrize(id) {
    return http.get(`/lottery/drawLotteryPrize/${id}`)
}

// 获得抽奖活动信息
export function getLotteryInfo(id) {
    return http.get(`/lottery/getLotteryInfo/${id}`)
}

export function getWinPrizePage(params) {
    return http.get(`/lottery/getWinPrizePage`, params)
}






