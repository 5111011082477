<template>
    <div id="WithDraw" :class="theme || 'theme-red-CB0709'">

        <div class="content">
            <van-nav-bar
                    title="我的余额"
                    left-arrow
                    @click-left="back"
            />

            <div class="money-text">
                <span>{{balance}}</span>
                <span>元</span>
            </div>

            <div class="info-item">
                <span>提现至微信零钱</span>
            </div>
            <div class="info-item withdraw-box">
                <div>¥</div>
                <input type="text" v-model="money" placeholder="最低0.3元起">
                <div @click="withdrawAll">全部</div>
            </div>

            <div class="btn" @click="getUp">
                提现
            </div>
            <div class="tips">提现免手续费，预计1-3个工作日到账</div>
        </div>
        <van-overlay :show="dialog">
            <div class="dialog-box">
                <van-loading vertical color="#0094ff">提现中...</van-loading>
            </div>

        </van-overlay>
    </div>
</template>

<script>
import loadsh from 'lodash'
import {searchMoney, withdraw, withdrawStatus } from '../../assets/api/user'
export default {
    name: "WithDraw",
    data() {
        return{
            balance: '0.00',
            money: '',
            dialog: false
        }
    },
    methods: {
        back() {
            this.$router.back()
        },
        async searchMoney() {
            let {code, data: {balance, userId} } = await searchMoney()
            if(code == 200) {
                this.balance = balance
            }
        },
        withdrawAll() {
            this.money = this.balance
        },
        getUp: loadsh.debounce(function () {
            console.log(222222)
            this.draw()
        }, 500),
        draw() {
            let money = Number(this.money)
            let balance = Number(this.balance)
            if (money < 0.3) {
                this.$toast('因受微信平台公户对个人转账最低0.3元限制，提现金额最低为0.3元')
            } else if (money > balance) {
                this.$toast('账户余额不足')
            } else {
                this.withdraw()
            }
        },
        async withdraw() {
            // console.log(this.$store)
            this.dialog = true
            try {
                let {code, data, message} = await withdraw({
                    weChatAppSelect: 'SHARE',
                    trade_type: 'JSAPI',
                    // openid: app.globalData.openId,
                    money: Number(this.money),
                })
                console.log(data, code)
                if(code == 200) {
                    this.getWithdrawStatus(data.businessId)
                }else {
                    this.dialog = false
                    if(message === '用户未绑定微信') {
                        this.$store.commit('CLEAR_TOKEN')
                        this.$toast('用户未绑定微信,请在首页绑定微信')

                    }else {
                        this.$toast(message)

                    }
                }
            }catch (e) {
                this.dialog = false
                this.$toast('提现失败')
            }

        },
        async getWithdrawStatus(businessId) {
            let num = 0
            let timer = ''

            let getStatus = async() => {
                let {code, data: {finish, success}} = await withdrawStatus({
                    businessId
                })
                if(code == 200) {
                    if(finish && success) {
                        this.dialog = false
                        this.$toast('提现成功')
                        this.money = ''
                        clearTimeout(timer)
                        this.searchMoney()

                    }
                }
                if(!finish) {
                    if (num < 3) {
                        timer = setTimeout(() => {
                            getStatus()
                        }, 1500)
                        num ++
                    }
                }

                if(finish && !success) {
                    this.dialog = false
                    this.$toast('提现失败')
                    clearTimeout(timer)
                }

                if(num >= 3 && !finish) {
                    this.dialog = false
                    this.$toast('提现失败')
                    clearTimeout(timer)
                }
            }
            getStatus()

        }
    },

    created() {
        this.searchMoney()
    },
    computed: {
        theme() {
            return this.$store.getters.getTheme
        },
        token() {
            return this.$store.getters.getToken
        }
    },
}
</script>

<style lang="less">
#WithDraw {
    max-width: 375px;
    margin: 0 auto;

    .van-nav-bar .van-icon {
        color: #333;
    }

}
</style>

<style scoped lang="less">
@import "../../assets/css/theme";

.dialog-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.module-theme(@theme, @tag) {

    .content {
        max-width: 375px;
        margin: auto;
        min-height: 100vh;
        //overflow: auto;
        padding-bottom: 126px;
        background: white;
        position: relative;

        nav {
            width: 375px;
            height: 45px;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: white;

            > div {
                width: 15%;
            }
        }

        .money-text {
            margin: 25px auto;

            span:nth-of-type(1) {
                font-size: 64px;
                color: #333;
            }

            span:nth-of-type(2) {
                font-size: 14px;
                color: #333;
            }
        }

        .info-item {
            height: 70px;
            display: flex;
            align-items: center;
            color: #333;
            border-bottom: 1px solid #F5F5F5;
            width: 334px;
            margin: 0 auto;
        }

        .withdraw-box {
            justify-content: space-between;


            > input {
                color: #333;
                //color: #9E9E9E;
                border: none;
                width: 70%;
            }

            div:nth-of-type(2) {
                color: @theme;
            }
        }
        .btn {
            width: 277px;
            height: 48px;
            background: @theme;
            border-radius: 30px;
            text-align: center;
            line-height: 48px;
            margin: 114px auto 8px;
            color: white;

        }

        .tips {
            text-align: center;
            font-size: 12px;
            color: #9E9E9E;
        }

    }


}
</style>