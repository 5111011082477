<template>
    <div id="VoteTemp-1" :class="theme || 'theme-red-CB0709'" v-scrollT v-title :data-title="info.title && info.title">
        <login v-if="show"></login>
        <div class="content">
            <nav>
                <div>
<!--                    <span v-show="fillStatus">信息</span>-->
                </div>
                <div></div>
                <div>
<!--                    <img @click="toPath('/AnswersTemp-1-rank')" src="../../../assets/images/Answer/rank-btn.png" alt="">-->
                </div>
            </nav>
            <!--            <img class="banner" src="../../../assets/images/VoteTemp-1/banner.png" alt="">-->
            <img class="banner" :src="info.themeImg && info.themeImg" alt="">
            <div class="content-box">
                <div class="box-top">
                    <div class="box-title">答题</div>
                    <div class="box-text text-content" v-html="info.introduction"></div>
                </div>
                <div class="box-bottom">
                    <div v-if="info.actStatus === '1'"
                         @click="toPath('/answers1-details')"
                         class="btn">开始答题</div>
<!--                    <img v-if="info.actStatus === '1'" class="btn" @click="toPath('/AnswersTemp-1-details')" src="../../../assets/images/Answer/btn.png" alt="">-->
                    <div v-else class="disable-btn">{{info.actStatus | formatterActStatus}}</div>
                    <div class="tip" v-show="false">每个用户只能答题次</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import login from '../../../components/login'
    import {getQaActivityInfo} from '../../../assets/api/answer'
    import {getAttachFormFillStatus} from '../../../assets/api/user'
    import wxShare from '../../../assets/js/wx-share'
    import {getIsApp, isWeixin, loginWx, WXauthorizeWx} from '../../../assets/js/wx-authorize'
    import {Toast, NavBar} from "vant";
    import lodash from 'lodash'
    import {formatterActStatus} from "../../../assets/js/untils";

    export default {
        name: "VoteTemp-1",
        components: {
            login,
            NavBar
        },
        data() {
            return {
                // 数据访问量啥啥啥
                count:{},
                //距离结束的时间
                //选项信息分页
                pageNum: 1,
                flag: false,
                noMore: false,
                isLoad: true,
                requestFlag: true,
                //接口返回接收
                groupId:'',
                //排行榜页码
                pageNo:1,
                moreFlag:true,

                //    介绍隐藏
                showIntro: false,
                //    排行隐藏
                showRank: false,
                //    投票隐藏
                searchCon: '',
                show: false,
                id: '1',
                info: {},
                fillStatus: '',
                relateStatus: ''
            }
        },
        created() {
            // this.getParams()
        },
        mounted() {
            setTimeout(async () => {
                const status = await this.getLogin()
                console.log(status)
                if (status) {
                    this.getParams()
                }
            }, 0)
        },
        methods: {
            getParams() {
                console.log(this.$route.query)
                this.id = this.$route.query.qaId
                this.getQaActivityInfo()
                this.getAttachFormFillStatus()
                // this.getVoteStatistInfo()
                // this.getVoteRankList()
            },
            getLogin() {
                return new Promise( (resolve) => {
                    console.log(552)
                    if (getIsApp()) {
                        if (this.isFlutter) {
                            window.h5SendAppToken = (data) => {
                                if (data) {
                                    console.log(data)
                                    this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)

                                    // this.deSubmit()
                                }
                            }
                            window.flutter_inappwebview.callHandler('h5GetApptoken')
                            resolve('flutter')
                        }
                        this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
                            console.log(data)
                            if (data) {
                                this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                                // this.getParams()
                                // this.getParams()
                                resolve('app')
                            }
                        })
                        this.$bridge.callHandler('h5GetApptoken')
                    } else if (!this.token) {
                        // this.getParams()
                        if (isWeixin()) {
                            loginWx()
                            resolve('wxNoToken')
                        } else {
                            this.show = true
                            resolve('h5NoToken')
                            // this.getParams()
                        }
                    } else {
                        resolve('h5')
                        // this.getParams()
                        // this.getParams()
                    }
                })
            },
            onChange(index) {
                this.active = index
                console.log(index)
            },
            async getQaActivityInfo() {
                let {code, msg, data} = await getQaActivityInfo({qaId: this.id})
                if(code === 200) {
                    this.info = data
                    if(isWeixin()) {
                        let {title, weShareSummary, weShareImg} = data
                        wxShare.loadWxConfig(title, weShareSummary, window.location.href, weShareImg)
                        if(this.isFlutter) {
                            window.flutter_inappwebview.callHandler('getShareUrl', {
                                shareName: title
                            })
                        }
                        this.$bridge.callHandler('getShareUrl', {
                            shareName: title
                        })
                    }

                }else if(code === 518){
                    this.$message.error('活动不存在')
                    // msg && this.$message.error(msg)
                }

            },
            async toPath(path) {
                const status = await this.getLogin()
                if (status !== 'wxNoToken' && status !== 'h5NoToken') {
                    if(!this.info.isMeetSingleParticipantsLimit || !this.info.isMeetTotalParticipantsLimit) {
                        this.$message.error('您今天的答题次数已用完，欢迎明天继续挑战。')
                        return false
                    }
                    if(this.relateStatus === '1') {
                        if(this.fillStatus === '0') {
                            this.$router.push({
                                path: '/answers1-form',
                                query: {
                                    id: this.id
                                }
                            })
                        }else {
                            this.$router.push({
                                path,
                                query: {
                                    id: this.id
                                }
                            })
                        }
                    }else {
                        this.$router.push({
                            path,
                            query: {
                                id: this.id
                            }
                        })
                    }
                }

                // if(this.token) {
                //     if(!this.info.isMeetSingleParticipantsLimit || !this.info.isMeetTotalParticipantsLimit) {
                //         this.$message.error('您今天的答题次数已用完，欢迎明天继续挑战。')
                //         return false
                //     }
                //     if(this.relateStatus === '1') {
                //         if(this.fillStatus === '0') {
                //             this.$router.push({
                //                 path: '/answers1-form',
                //                 query: {
                //                     id: this.id
                //                 }
                //             })
                //         }else {
                //             this.$router.push({
                //                 path,
                //                 query: {
                //                     id: this.id
                //                 }
                //             })
                //         }
                //     }else {
                //         this.$router.push({
                //             path,
                //             query: {
                //                 id: this.id
                //             }
                //         })
                //     }
                // }else {
                //     if (isWeixin()) {
                //         loginWx()
                //     } else {
                //         this.show = true
                //     }
                // }
            },
            async getAttachFormFillStatus() {
                let {code, msg, data: {fillStatus, relateStatus}} = await getAttachFormFillStatus({
                    actId: this.id,
                    actType: '4'
                })

                if(code === 200) {
                    this.fillStatus = fillStatus
                    this.relateStatus = relateStatus
                }
            }
        },
        computed: {
            theme() {
                return this.$store.getters.getTheme
            },
            token() {
                return this.$store.getters.getToken
            },
            loginDialog() {
                return this.$store.getters.getLoginDialog
            },
            isFlutter() {
                return this.$store.getters.getFlutter
            }
        },
        watch: {
            loginDialog(val) {
                console.log('open', val)
                if(val) {
                    this.show = true
                }
            },
            token(val) {
                if(val) {
                    console.log('get refresh')
                    this.getQaActivityInfo()
                    this.getAttachFormFillStatus()
                }
            },
            async isFlutter() {
                const status = await this.getLogin()
                console.log(status)
                if (status) {
                    this.getParams()
                }
            }
        },
        filters: {
            formatterActStatus
        }

    }
</script>

<style scoped lang="less">
    @import "../../../assets/css/theme";

    .module-theme(@theme, @tag) {

        .content {
            max-width: 375px;
            margin: auto;
            //min-height: 885px;
            //padding-bottom: 126px;
            background-image: url("../../../assets/images/VoteTemp-1/bg.png");
            background-size: 100% auto;
            background-repeat: no-repeat;
            position: relative;

            nav {
                width: 375px;
                height: 45px;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: white;

                > div {
                    width: 15%;
                }
            }

            .banner {
                width: 375px;
            }

            .content-box {
                width: 95%;
                margin: 20px auto;

                .box-top {
                    background: url("../../../assets/images/Answer/box-bg.png");
                    width: 100%;
                    min-height: 300px;
                    background-size: 100% 100%;
                    position: relative;
                    padding: 30px 0 20px;
                    box-sizing: border-box;

                    .box-title {
                        background: url("../../../assets/images/Answer/box-title.png");
                        background-size: 100% 100%;
                        width: 220px;
                        height: 45px;
                        line-height: 40px;
                        position: absolute;
                        left: 50%;
                        top: 0;
                        transform: translateX(-50%);
                        font-size: 18px;
                        color: @theme;
                        font-weight: bold;
                    }

                    .box-text {
                        height: 240px;
                        width: 90%;
                        margin: 20px auto;
                        text-align: left;
                        font-size: 14px;
                        word-break: break-all;
                        overflow-y: scroll;

                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                }

                .box-bottom {
                    background: url("../../../assets/images/Answer/box-bg1.png");
                    width: 100%;
                    min-height: 120px;
                    background-size: 100% 100%;
                    //padding-top: 40px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;

                    .btn {
                        display: inline-block;
                        margin: 0 auto;
                        background: rgb(232,129,60);
                        color: white;
                        font-size: 16px;
                        width: 280px;
                        height: 45px;
                        line-height: 45px;
                        border-radius: 40px;
                    }

                    .disable-btn {
                        width: 280px;
                        height: 45px;
                        line-height: 45px;
                        margin: 0 auto;
                        background: #999999;
                        border-radius: 50px;
                        color: white;
                    }

                    .tip {
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }

    }
</style>
