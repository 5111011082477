import http from '../js/http'



//获得投票信息
export function getRegisterInfo(id, key) {
    return http.get(`/regist/getRegisterInfo/${id}?encrypt=${key}`)
}


//获得投票信息
export function getRegistItem(params) {
    return http.get(`/regist/getRegistItem`, params)
}

//提交表单
export function submitRegist(params) {
    return http.post(`/regist/submitRegist`, params)
}

export function checkPay(params) {
    return http.get(`/activityPay/checkPay?actIdInfo=${params.payQueryId}`)
}


