<template>
    <div id="QuestionnaireTemp-1" :class="theme || 'theme-red-CB0709'" v-title :data-title="info.title && info.title">
        <login v-if="show"></login>
        <div class="content-1 QuestionnaireTemp-1">
            <!--            <img class="banner" src="../../../assets/images/VoteTemp-1/banner.png" alt="">-->
            <img class="banner" :src="info.themeImg && info.themeImg" alt="">
            <!--            活动规则-->
            <div class="ruleCon height-auto">
                <div class="title">
                    <img src="../../../assets/images/Questionnaire/title-icon.png" alt="">
                    <span>{{ info.title }}</span>
                    <img src="../../../assets/images/Questionnaire/edit.png" alt="">
                </div>
                <div class="html text-content" v-html="info.introduction"></div>
            </div>

            <div class="input-box" v-show="info.actStatus === '1'">
                <img class="input-title" src="../../../assets/images/Questionnaire/ques-title.png" alt="">
                <van-form>
                    <div class="input-item"
                         v-for="(item, index) of info.actSurveyQuestPoList"
                         :key="index">
                        <div> {{ item.isNecessary === '1' ? '*' : ''}} {{
                                item.questContent
                            }}
                        </div>
                        <van-field
                                v-model="item.answer"
                                :placeholder="item.questContent"
                                v-if="item.questType == 1"
                                placeholder="请输入内容"
                        />

                        <el-input
                                v-if='item.questType == 2'
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                v-model="item.answer">
                        </el-input>
                        <van-radio-group v-model="item.answer" v-if='item.questType == 4'>
                            <van-radio v-for="(radio, index) of item.actSurveyQuestOptionList"
                                       :key="index"
                                       :name="radio"
                                       style="margin-bottom: 15px"
                                       checked-color="#ee0a24">
                                {{ radio.optionContent }}
                            </van-radio>
                            <!--                            <van-radio name="2" checked-color="#ee0a24">单选框 2</van-radio>-->
                        </van-radio-group>
                        <van-uploader
                                v-model="preImg"
                                :max-count="1"
                                v-if='item.questType == 5'
                                :after-read="(file) => afterRead(file, item)"
                                multiple/>
                        <van-checkbox-group v-model="item.answer" v-if='item.questType == 9'>
                            <van-checkbox
                                    v-for="(check, index) of item.actSurveyQuestOptionList"
                                    :name="check"
                                    style="margin-bottom: 15px"
                                    shape="square">{{ check.optionContent }}
                            </van-checkbox>
                        </van-checkbox-group>
                    </div>
                    <!--                    <van-field-->
                    <!--                            v-model="test"-->
                    <!--                            name="用户名"-->
                    <!--                            label="用户名"-->
                    <!--                            placeholder="用户名"-->
                    <!--                            :rules="[{ required: true, message: '请填写用户名' }]"-->
                    <!--                    />-->
                    <!--                    <div class="input-item">-->
                    <!--                        <div>题目</div>-->
                    <!--                        <el-input-->
                    <!--                                type="textarea"-->
                    <!--                                :rows="2"-->
                    <!--                                placeholder="请输入内容"-->
                    <!--                                v-model="textarea">-->
                    <!--                        </el-input>-->
                    <!--                    </div>-->
                    <!--                    <div class="input-item">-->
                    <!--                        <div>题目</div>-->
                    <!--                        <van-uploader v-model="fileList"-->
                    <!--                                :after-read="(file) => afterRead(file, 'fileList')"-->
                    <!--                                multiple />-->
                    <!--                    </div>-->
                    <!--                    <div class="input-item">-->
                    <!--                        <div>题目</div>-->
                    <!--                        <van-radio-group v-model="radio">-->
                    <!--                            <van-radio name="1" checked-color="#ee0a24">单选框 1</van-radio>-->
                    <!--                            <van-radio name="2" checked-color="#ee0a24">单选框 2</van-radio>-->
                    <!--                        </van-radio-group>-->
                    <!--                    </div>-->
                    <!--                    <div class="input-item">-->
                    <!--                        <div>题目</div>-->
                    <!--                        <van-checkbox-group v-model="result">-->
                    <!--                            <van-checkbox name="a" shape="square">复选框 a</van-checkbox>-->
                    <!--                            <van-checkbox name="b" shape="square">复选框 b</van-checkbox>-->
                    <!--                        </van-checkbox-group>-->
                    <!--                    </div>-->


                </van-form>
            </div>
            <div class="bottom-btn">
                <div v-if="info.actStatus === '1'" @click="toSign">提交</div>
                <div v-else class="disable-btn">{{ info.actStatus | formatterActStatus}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import login from '../../../components/login'
import {getSurveyInfo, checkSurveyLimit, submitSurvey} from '../../../assets/api/questionnaire'
import wxShare from '../../../assets/js/wx-share'
import {getIsApp, isWeixin, loginWx, WXauthorizeWx} from '../../../assets/js/wx-authorize'
import {Toast} from "vant";
import {formatterActStatus} from "../../../assets/js/untils";
import lodash from 'lodash'

export default {
    name: "QuestionnaireTemp-1",
    components: {
        login,
    },
    data() {
        return {
            active: 0,
            // 数据访问量啥啥啥
            count: {},
            //距离结束的时间
            intervalToEnd: '',
            second: '',
            min: '',
            time: '',
            day: '',
            voteGroupList: [],
            //选项信息分页
            pageNum: 1,
            flag: false,
            noMore: false,
            isLoad: true,
            requestFlag: true,
            //接口返回接收
            groupId: '',
            //排行榜页码
            pageNo: 1,
            moreFlag: true,

            //    介绍隐藏
            showIntro: false,
            //    排行隐藏
            showRank: false,
            rankList: [],
            //    投票隐藏
            showVote: false,
            searchCon: '',
            voteList: [],
            show: false,
            id: '1',
            canParticipate: false,
            info: {},
        }
    },
    beforeDestroy() {
    },
    created() {

    },
    mounted() {
        console.log(this.token)
        this.getLogin()
        // if (!this.token) {
        //     if (isWeixin()) {
        //         loginWx()
        //     } else {
        //         this.show = true
        //     }
        // }
    },
    methods: {
        getParams() {
            this.id = this.$route.query.surveyId
            console.log(this.$route.query)
            this.getSurveyInfo()
            this.$nextTick(() => {
                if(this.token) {
                    this.checkSurveyLimit()
                }
            })

        },
        getLogin() {
            if (getIsApp()) {
                if(this.isFlutter) {
                    window.h5SendAppToken = (data) => {
                        if (data) {
                            this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                            this.getParams()
                        }
                    }
                    window.flutter_inappwebview.callHandler('h5GetApptoken')
                }
                this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
                    console.log(data)
                    if (data) {
                        this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                        // this.getParams()
                        this.getParams()
                    }
                })
                this.$bridge.callHandler('h5GetApptoken')
            } else if (!this.token) {
                // this.getParams()
                if (isWeixin()) {
                    loginWx()
                } else {
                    this.show = true
                    this.getParams()
                }
            } else {
                this.getParams()
                // this.getParams()
            }
        },
        async checkSurveyLimit() {
            let {data: {canParticipate}, code} = await checkSurveyLimit(this.id)
            if (code == 200) {
                this.canParticipate = canParticipate

            }
        },
        async getSurveyInfo() {
            let {data, code} = await getSurveyInfo(this.id)
            if (code == 200) {

                data.actSurveyQuestPoList.map(item => {
                    if(item.questType == 4 || item.questType == 9) {
                        item.answer = []
                    }else {
                        item.answer = ''
                    }

                })

                console.log(data)
                this.info = data
                if(isWeixin()) {
                    let {title, weShareSummary, weShareImg} = data
                    wxShare.loadWxConfig(title, weShareSummary, window.location.href, weShareImg)
                }
                // this.$set(ruleList[0], 'ruleP2', `${data.registStartTime}-${data.registEndTime}`)
                // this.$set(ruleList[1], 'ruleP2', `${data.actStartTime}-${data.actEndTime}`)
                // this.$set(ruleList[2], 'ruleP2', data.actTel)
                // this.$set(ruleList[3], 'ruleP2', data.address)
            }

        },
        //   活动评选数据 (访问量啥啥啥)
        // getVoteStatistInfo() {
        //     let params = {
        //         "signId": this.id,
        //     };
        //     getVoteStatistInfo(params).then(res => {
        //         console.log(res.data)
        //         if (res.code == 200) {
        //             this.count = res.data
        //         }
        //     }).catch(err => {
        //     })
        // },
        // 进入个人详情页
        toSign() {
            if (getIsApp()) {
                if(this.isFlutter) {
                    window.h5SendAppToken = (data) => {
                        if (data) {
                            this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                            this.deSubmit()
                        }
                    }
                    window.flutter_inappwebview.callHandler('h5GetApptoken')
                }
                this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
                    console.log(data)
                    if (data) {
                        this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                        this.deSubmit()
                        // this.getParams()
                    }
                })
                this.$bridge.callHandler('h5GetApptoken')
            } else if (!this.token) {
                // this.getParams()
                if (isWeixin()) {
                    loginWx()
                } else {
                    this.show = true
                }
            } else {
                // this.getParams()
                if(!this.canParticipate) {
                    this.$message.error('您的次数已用完')
                }else {
                    this.deSubmit()
                }
            }

            //
            // if (!this.token) {
            //     if (isWeixin()) {
            //         loginWx()
            //     } else {
            //         this.show = true
            //     }
            // }else {
            //     if(!this.canParticipate) {
            //         this.$message.error('您的次数已用完')
            //     }else {
            //         this.deSubmit()
            //     }
            // }
        },
        deSubmit: lodash.debounce(function () {
            this.submit()
        }, 200),
        async submit() {
            let flag = true
            let submitQuestList = []
            console.log(this.info.actSurveyQuestPoList)
            for (let item of this.info.actSurveyQuestPoList){
                if(item.isNecessary === '1') {
                    console.log(item)
                    if(item.answer == '' || item.answer.length == 0) {
                        flag = false
                    }
                }
            }
            if(!flag) {
                this.$message.error('请填写必填选项')
                return false
            }
            submitQuestList = this.info.actSurveyQuestPoList.map(item => {
                let temData = null

                if(item.questType == 4 || item.questType == 9) {
                    let arr = Array.isArray(item.answer) ? item.answer : [item.answer]
                    temData = {
                        questId: item.questId,
                        submitQuestOptionList: arr.map(box => box.optionId)
                    }
                }else {
                    temData = {
                        questId: item.questId,
                        text: item.answer
                    }
                }
                return temData
            })

            let params = {
                surveyId: this.id,
                submitQuestList
            }
            console.log(params)
            let {code, msg, data} = await submitSurvey(params)
            if(code == 200) {
                // this.lotteryId = lotteryId
                if(data) {
                    this.$dialog.confirm({
                        title: '提交成功',
                        message: '已成功提交，恭喜您获得一次抽奖机会，可点击下方“前往抽奖”按钮进入抽奖页面。',
                        confirmButtonText: '前往抽奖'
                    })
                            .then(() => {
                                // on confirm
                                this.$router.push({
                                    path: '/draw1',
                                    query: {
                                        lotteryId: data
                                    }
                                })
                            })
                }else {
                    this.$message.success('提交成功')
                }
                this.checkSurveyLimit()
                this.getSurveyInfo()

            }else {
                this.$message.error(msg)
            }
        }
        //倒计时时间转换
    },
    watch:{
        token(val) {
            this.getParams()
        },
        loginDialog(val) {
            console.log('open', val)
            if(val) {
                this.show = true
            }
        },
        isFlutter() {
            this.getLogin()
        }
    },
    computed: {
        theme() {
            return this.$store.getters.getTheme
        },
        token() {
            return this.$store.getters.getToken
        },
        loginDialog() {
            return this.$store.getters.getLoginDialog
        },
        isFlutter() {
            return this.$store.getters.getFlutter
        }
    },
    filters: {
        formatterActStatus
    }

}
</script>
<style lang="less">
@import "../../../assets/css/theme";

.module-theme(@theme, @tag) {
    .QuestionnaireTemp-1 {
        .van-nav-bar .van-icon {
            color: rgb(51, 51, 51);
        }

        .van-radio {
            margin: 5px auto;
        }

        .van-checkbox {
            margin: 5px auto;
        }


        .van-checkbox__icon--checked .van-icon {
            color: #fff;
            background-color:  @theme !important;
            border-color: @theme !important;
        }


    }
}

</style>

<style scoped lang="less">
@import "../../../assets/css/theme";

.module-theme(@theme, @tag) {
    .content-1 {
        max-width: 375px;
        margin: auto;
        min-height: 100vh;
        padding-bottom: 126px;
        background-image: url("../../../assets/images/VoteTemp-1/bg.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        box-sizing: border-box;

        .banner {
            width: 100%;
            //max-width: 375px;
            display: block;
            height: auto;
        }

        /*致敬劳动者评选活动*/

        .starCon {
            width: calc(100% - 30px);
            height: 175px;
            background: #FFFFFF;
            border-radius: 2px;
            margin: 20px 15px;

            .starTop {
                display: flex;
                justify-content: flex-start;
                height: 46px;
                margin: 0 10px;
                border-bottom: 1px solid #D4D4D4;
                line-height: 46px;
                font-weight: bold;

                .star {
                    width: 11px;
                    height: 16px;
                    display: block;
                    margin: 15px 7px 15px 0;
                }
            }

            .voteNumbers {
                height: 66px;
                background: @theme;
                border-radius: 4px;
                margin: 15px 10px;
                display: flex;
                justify-content: space-between;

                .voteNumCon {
                    width: 108px;
                    height: 42px;
                    margin: 11px 0;
                    border-right: 1px solid #D8D8D8;
                    color: #FFF;

                    .p1 {
                        font-size: 20px;
                        line-height: 28px;
                    }

                    .p2 {
                        font-size: 13px;
                        line-height: 18px;
                    }
                }

                .voteNumCon:nth-child(3) {
                    border-right: none;
                }
            }

            .numP {
                color: @theme;
            }
        }

        /*活动规则*/

        .ruleCon {
            min-height: 144px;
            background: #FFFFFF;
            border-radius: 2px;
            margin: 0 15px 20px;
            padding-bottom: 20px;
            position: relative;

            .ruleTop {
                display: flex;
                justify-content: center;
                padding-top: 15px;

                .rule {
                    color: @theme;
                    height: 22px;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 22px;
                }

                .arrow {
                    width: 31px;
                    height: 14px;
                    display: block;
                    margin: 4px 5px;
                }
            }

            .ruleItem {
                display: flex;
                justify-content: flex-start;
                font-size: 14px;
                line-height: 20px;
                margin: 8px 14px;
                min-height: 20px;
                flex-wrap: wrap;

                .strTime {
                    width: 14px;
                    height: 14px;
                    display: block;
                    margin: 3px 6px 3px 0;
                }

                .ruleP1 {
                    color: #333;
                    margin-right: 10px;
                }

                .ruleP2 {
                    color: #666;
                }

                .ruleP3 {
                    color: #666;
                    margin-left: 20px;
                }
            }
        }

        .html {
            width: 95%;
            margin: 10px auto;
            text-align: left;
            font-size: 12px;
            word-break: break-all;
            z-index: 100;
            transform: translateZ(10px);
            //position: absolute;

            //> *{
            //    z-index: 200;
            //}
        }

        /*底图*/

        .footBg {
            max-width: 375px;
            margin: auto;
            width: 100%;
            height: 156px;
            background-image: url("../../../assets/images/VoteTemp-1/bottomBg.png");
            background-size: 100% 100%;
            position: fixed;
            bottom: 0;
            left: calc(50% - 187.5px);
            z-index: -2;

            .footRule {
                width: 100%;
                font-size: 12px;
                color: #666666;
                line-height: 17px;
                text-align: center;
                margin-top: 50px;
            }
        }

        /*tabbar*/

        .foot {
            .van-tabbar--fixed {
                max-width: 375px !important;
                margin: 0 calc(50% - 187.5px);
                padding-bottom: 20px !important;
            }
        }

        .tabCon {
            max-width: 375px;
            height: auto;
            margin: 0 15px;
            /*活动介绍*/

            .introCon {
                /*height: 621px;*/
                height: auto;
                padding-bottom: 20px;
                background: #FFFFFF;
                border-radius: 2px;
                font-size: 13px;
                color: #666666;
                line-height: 18px;

                .introTop {
                    display: flex;
                    justify-content: center;
                    padding-top: 15px;

                    .intro {
                        color: @theme;
                        height: 22px;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 22px;
                    }

                    .arrow {
                        width: 31px;
                        height: 14px;
                        display: block;
                        margin: 4px 5px;
                    }
                }

                .introItem1 {
                    margin: 0 15px;

                    .introItem {
                        display: flex;
                        justify-content: flex-start;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        line-height: 22px;
                        margin: 25px 0 5px 0;

                        .dot {
                            width: 8px;
                            height: 8px;
                            display: block;
                            margin: 6px 5px 6px 0;
                        }
                    }

                    .introP1 {
                        margin-left: 13px;
                        text-align: left;
                    }
                }

                .introItem2 {
                    margin: 0 15px;

                    .introItem {
                        display: flex;
                        justify-content: flex-start;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        line-height: 22px;
                        margin: 21px 0 5px 0;

                        .dot {
                            width: 8px;
                            height: 8px;
                            display: block;
                            margin: 6px 5px 6px 0;
                        }
                    }

                    .introP1 {
                        margin-left: 13px;
                        text-align: left;
                    }

                    .introP2 {
                        font-size: 12px;
                        font-weight: bold;
                        color: #333333;
                        line-height: 17px;
                        margin: 10px 0 4px;
                    }
                }
            }

            /* 排行榜*/

            .rankCon {
                min-height: 386px;
                height: auto;
                background: #FFFFFF;
                border-radius: 2px;
                font-size: 13px;
                color: #666666;
                line-height: 18px;
                padding-bottom: 10px;

                .introTop {
                    display: flex;
                    justify-content: center;
                    padding-top: 15px;

                    .intro {
                        color: @theme;
                        height: 22px;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 22px;
                    }

                    .cup {
                        width: 22px;
                        height: 22px;
                        display: block;
                        margin-right: 4px;
                    }
                }

                .introItem {
                    padding: 0 15px 0 11px;
                    height: 36px;
                    font-size: 14px;
                    color: #333333;
                    line-height: 36px;
                    display: flex;
                    justify-content: space-between;
                    margin: 10px 0;

                    .introItem1 {
                        display: flex;
                        justify-content: flex-start;

                        .cupImg {
                            width: 28px;
                            height: 28px;
                            display: block;
                            margin-top: 3px;
                            margin-right: 33px;
                            line-height: 28px;
                            color: @theme;
                            font-weight: bold;
                            text-align: center;
                        }

                        .cupName {
                            max-width: 208px;
                        }
                    }

                    .cupNum {
                        font-size: 16px;
                        font-weight: bold;
                    }
                }

                .more {
                    margin-top: 30px;
                }
            }

            /*投票*/

            .voteCon {
                min-height: 386px;
                border-radius: 2px;
                font-size: 13px;
                color: #666666;
                line-height: 18px;

                .voteTop {
                    display: flex;
                    justify-content: flex-start;
                    /*margin: 0 15px;*/
                    position: relative;
                    height: 34px;
                    line-height: 34px;

                    .searchImg {
                        width: 22px;
                        height: 23px;
                        position: absolute;
                        left: 2px;
                        top: 6px;
                    }

                    .input {
                        width: 80%;
                        border: none;
                        outline: none;
                        font-size: 14px;
                        color: #999999;
                        padding: 0 24px;
                    }

                    .send {
                        width: 20%;
                        background-color: @theme;
                        color: #fff;
                        text-align: center;
                    }
                }

                .introItem {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    justify-items: center;
                    margin-top: 20px;

                    .voteItem {
                        width: 170px;
                        height: 293px;
                        background: #FFFFFF;
                        border-radius: 2px;
                        margin: 0 5px 6px 0;
                        position: relative;

                        .topNum {
                            background-image: url("../../../assets/images/VoteTemp-1/topRank.png");
                            background-repeat: no-repeat;
                            background-size: 100%;
                            width: 34px;
                            height: 23px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            font-size: 12px;
                            color: #FFFFFF;
                            line-height: 24px;
                            padding-left: 8px;
                            text-align: left;
                        }

                        .play {
                            width: 29px;
                            height: 29px;
                            display: block;
                            position: absolute;
                            left: 10px;
                            top: 143px;
                        }

                        .voteHead {
                            width: 170px;
                            height: 188px;
                            border-radius: 2px;
                            object-fit: cover;
                        }

                        .voteNum {
                            color: @theme;
                            font-size: 16px;
                            line-height: 22px;
                            margin-top: 8px;
                        }

                        .voteTitle {
                            font-size: 12px;
                            color: #333333;
                            line-height: 17px;
                            margin-top: 2px;
                        }

                        .voteImg {
                            width: 70px;
                            height: 24px;
                            margin-top: 13px;
                        }
                    }

                    .voteItem:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .title {
        width: 90%;
        margin: 0 auto;
        padding-top: 15px;
        color: rgba(51, 51, 51, 1);
        display: flex;
        align-items: start;
        justify-content: space-between;
        z-index: 0;
        position: relative;

        > span {
            width: 100%;
            text-align: left;
            margin-left: 5px;
            //margin: 0 35px 0 5px;
            z-index: 1;
            word-break: break-all;
            font-weight: bold;
        }

        > img:nth-of-type(1) {
            margin-top: 5px;
            width: 15px;
        }

        > img:nth-of-type(2) {
            position: absolute;
            width: 60px;
            z-index: 0;
            right: 0;
        }
    }

    .spent {
        font-size: 16px;
        color: @theme;
        margin: 8px 14px;
        text-align: left;
    }

    .sign-number {
        margin: 8px 14px;
        text-align: left;
        font-size: 16px;
        padding-bottom: 20px;


        > span {
            color: @theme;
        }
    }

    .bottom-btn {
        width: 100%;
        height: 60px;
        position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;

        > div {
            max-width: 375px;
            background: @theme;
            color: white;
            width: 90%;
            height: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .disable-btn {
            background: #999999;
        }
    }

    .height-auto {
        height: auto;
    }

    .input-box {
        width: 90%;
        margin: 0 auto;
        background: white;
        padding-top: 10px;

        .input-title {
            width: 130px;
        }

        .input-item {
            padding: 10px 16px;
            font-size: 16px;

            > div {
                text-align: left;
                margin-bottom: 10px;

            }
        }
    }
    // 其他样式也如此
}
</style>
