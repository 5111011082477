import axios from "axios"
import wx from 'weixin-js-sdk'
import qs from 'qs'

export default  {
  loadWxConfig (wxtitle, wxdesc, wxlink, wximgUrl) {
    wxlink = window.location.href
    let [params, query] = wxlink.split('?')
    query = qs.parse(query)
    delete query.code
    delete query.state
    console.log(qs.stringify(query))
    // console.log(params + '?' + qs.stringify(query))
    wxlink = params + '?' + qs.stringify(query)
    axios.get('https://apf.ql1m.com/weixin/get_page_config', {
      params: {
        url: window.location.href
      }
    }).then(rsp => {
      console.log(rsp)
      if (rsp.data.rc == '0') {
          console.log(11111, rsp.data)
        let data = rsp.data.data;
        this.setWxConfig(data, wxtitle, wxdesc, wxlink, wximgUrl);
      } else {
        console.log(rsp);
      }
    })
    // $.ajax({
    //   type: 'GET',
    //   url: '/weixin/get_page_config',
    //   data: {"url": window.location.href},
    //   dataType: 'json',
    //   success: function (rsp) {
    //     if (rsp.rc == '0') {
    //       var data = rsp.data;
    //       _s.setWxConfig(data, wxtitle, wxdesc, wxlink, wximgUrl);
    //     } else {
    //       console.log(rsp);
    //     }
    //   }
    // });
  },
  setWxConfig (a, wxtitle, wxdesc, wxlink, wximgUrl) {
    console.log(a, wxtitle, wxdesc, wxlink, wximgUrl)
    wx.config({
      debug: false,
      appId: a["appId"],
      timestamp: a["timestamp"],
      nonceStr: a["nonceStr"],
      signature: a["signature"],
      jsApiList: ["onMenuShareTimeline",
        "onMenuShareAppMessage",
        "onMenuShareQQ",
        "onMenuShareWeibo",
        "onMenuShareQZone",
        "hideMenuItems"]
    });
    wx.ready(() => {
      wx.hideMenuItems({
        menuList: ["menuItem:copyUrl", "menuItem:editTag", "menuItem:delete",
          "menuItem:openWithQQBrowser", "menuItem:openWithSafari", "menuItem:share:email",
          "menuItem:originPage", "menuItem:readMode", "menuItem:share:brand"]
      });
      wx.onMenuShareAppMessage({
        title: wxtitle,
        desc: wxdesc,
        link: wxlink,
        imgUrl: wximgUrl,
        success: function (res) {
            console.log(res)
            // alert(JSON.stringify(res))
          // $.ajax({
          //   url: "addshare",
          //   data: {},
          //   type: "post",
          //   dataType: "json",
          //   success: function (res) {
          //   }
          // });
        },
        fail: function (res) {
            console.log(res)
          // alert(JSON.stringify(res));
        }
      });

      wx.onMenuShareTimeline({
        title: wxtitle,
        desc: wxdesc,
        link: wxlink,
        imgUrl: wximgUrl,
        success: function (res) {
          // $.ajax({
          //   url: "addshare",
          //   data: {},
          //   type: "post",
          //   dataType: "json",
          //   success: function (res) {
          //   }
          // });
        },
        cancel: function (res) {
        },
        fail: function (res) {
          alert('错误');
        }
      });
    });
    wx.error(function (res) {
      console.log(res);
    });
  }
}
