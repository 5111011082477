import CryptoJS from 'crypto-js'
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDjA/i9xoM4QbB4Gi251lUKrhxz06CcpJI4zEGj8f1xsGF5IgzVzgQGBb9quAv826y8r1Bs2aE9r6cszL0wJwmo8VpC2E0VkZYHB1SvwH941DrZtXl89vD4268lhR9vK5gZ1YV52o0iXHaSb5z0pxeQbohY++Ap29G/hGmVpXTXbQIDAQAB'
export function generatekey(num) {
    let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let key = "";
    for (var i = 0; i < num; i++) {
        let randomPoz = Math.floor(Math.random() * library.length);
        key += library.substring(randomPoz, randomPoz + 1);
    }
    return key;
}
export function Encrypt(word, KEY) {
    let key = CryptoJS.enc.Utf8.parse(KEY);
    let srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.CBC,
        iv:key,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString().toUpperCase()
}


export function Decrypt(word, KEY) {
    let key = CryptoJS.enc.Utf8.parse(KEY);

    let Hex = CryptoJS.enc.Hex.parse(word);
    let src = CryptoJS.enc.Base64.stringify(Hex);

    let decrypt = CryptoJS.AES.decrypt(src, key, {
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
