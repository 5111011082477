import http from '../js/http'



//答题获得详情
export function getQaActivityInfo(params) {
    return http.get(`/qa/getQaActivityInfo`, params)
}

// 获得问题列表

export function getQaQuestList(params) {
    return http.get(`/qa/getQaQuestList`, params)
}

export function getFindRecordDto(params) {
    return http.get(`/qa/findRecordDto`, params)
}

// 提交问题
export function submitQaAnswer(data) {
    return http.post(`/qa/submitQaAnswer`, data)
}

export function getQaRank(params) {
    return http.get(`/qa/getQaRank`, params)
}






