<template>
    <div id="PrizeList" :class="theme || 'theme-red-CB0709'">
        <van-nav-bar
                title="中奖记录"
                left-arrow
                @click-left="back"
        />
        <div class="content">
            <div class="prize-list">
                <div class="prize-item" v-for="(item, index) of list" :key="index">
                    <div class="prize-info">
                        <img :src="item.prizeImg" alt="">
                        <div class="title-box">
                            <div>{{ item.prizeName }}</div>
                            <div>获奖时间：{{ item.createTIme }}</div>
                        </div>
                    </div>
                    <div class="prize-introduce">
                        <div @click="openInfo(item)">
                            <span>使用说明</span>
                            <img src="../../../assets/images/Draw/more.png" :class="`${!item.show && 'hidden-icon'}`" alt="">
                        </div>
                        <div v-show="item.prizeType == 3" class="withdraw" @click="withdraw">提现</div>
                    </div>
                    <div class="prize-content" v-show="item.show">
                        {{ item.prizeDeclare }}
                    </div>
                </div>
            </div>
            <div class="more-btn" @click="getMore" v-show="flag">{{flag ? '加载更多' : '加载完毕'}}</div>
        </div>
    </div>
</template>

<script>

import {getWinPrizePage} from '../../../assets/api/draw'
export default {
    name: "PrizeList",
    data() {
        return {
            id: '',
            form: {
                pageSize: 10,
                pageNo: 1,
                status: ''
            },
            list: [],
            total: 0,
            flag: true
        }
    },
    methods: {
        back() {
            this.$router.back()
        },
        getParams() {
            this.id = this.$route.query.id
            this.getPrizeList()
        },
        async getPrizeList() {
            if(!this.flag) {
                this.$toast('已经没有更多数据了')
                return false
            }
            let {data: {records, total}, code, msg} = await getWinPrizePage({
                ...this.form,
                lotteryId: this.id
            })
            if(code === 200) {
                console.log(records.length < this.form.pageSize)
                if(records.length < this.form.pageSize){
                    this.flag = false
                }
                this.total = total
                let list = records.map(item => {
                    item.show = false
                    return item
                })
                this.list = this.list.concat(list)
            }

        },
        openInfo(item) {
            item.show = !item.show
        },
        getMore(){
            this.form.pageNo ++
            this.getPrizeList()
        },
        withdraw() {
            this.$router.push('/withdraw')
        }
    },
    created() {
        this.getParams()
    },
    computed: {
        theme() {
            return this.$store.getters.getTheme
        },
        token() {
            return this.$store.getters.getToken
        }
    },
}
</script>

<style lang="less">
#PrizeList {
    max-width: 375px;
    margin: 0 auto;
    .van-nav-bar .van-icon {
        color: #333;
    }

}
</style>

<style scoped lang="less">
@import "../../../assets/css/theme";

.module-theme(@theme, @tag) {

    .content {
        max-width: 375px;
        margin: auto;
        min-height: 885px;
        padding-bottom: 126px;
        background-image: url("../../../assets/images/VoteTemp-1/bg.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        position: relative;

        nav {
            width: 375px;
            height: 45px;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: white;

            > div {
                width: 15%;
            }
        }

        .prize-item {
            width: 355px;
            min-height: 128px;
            background: #FFFFFF;
            border-radius: 5px;
            margin: 10px auto;
            padding: 20px 0 0;

            .prize-info {
                width: 315px;
                height: 82px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #EBEBEB;


                > img {
                    width: 50px;
                    height: 50px;
                }

                .title-box {

                    > div {
                        text-align: left;
                        &:nth-of-type(1) {
                            width: 230px;
                            font-size: 16px;
                            overflow: hidden;
                        }

                        &:nth-of-type(2) {
                            margin-top: 11px;
                            width: 230px;
                            overflow: hidden;
                            font-size: 12px;
                            color: #9E9E9E;
                        }
                    }
                }

            }
            .prize-introduce {
                height: 45px;
                width: 315px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .hidden-icon{
                    transform: rotate(180deg);
                }

                > div {
                    color: #727375;
                    font-size: 12px;
                    display: flex;
                    align-items: center;

                    > img {
                        width: 12px;
                    }


                }
            }

            .prize-content {
                width: 315px;
                margin: 0 auto;
                font-size: 12px;
                text-align: left;
                padding-bottom: 5px;
                word-break: break-all;

            }
        }
    }
    .more-btn {
        border-radius: 5px;
        border: 1px solid @theme;
        color: @theme;
        font-size: 12px;
        padding: 2px 4px;
        width: 120px;
        margin: 0 auto;
    }

    .withdraw {
        width: 88px;
        height: 29px;
        border-radius: 14px;
        background: rgba(232,129,60);
        color: white!important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
</style>