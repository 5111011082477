<template>
    <div id="Details" :class="theme || 'theme-red-CB0709'" v-scrollT>
        <login v-if="show"></login>
        <van-dialog v-model="dialog">
            <div class="dialog-box">
                <div class="dialog-rate">
                    <span>答题正确率</span>
                    <span>{{ dialogInfo && dialogInfo.accuracy }}%</span>
                </div>
                <div class="dialog-time">
                    <div>{{ dialogInfo && dialogInfo.timeCost | formatSeconds }}</div>
                    <div>用时</div>
                </div>
                <div class="dialog-info">
                    <div>
                        <div>{{ dialogInfo && dialogInfo.wrongCount }}</div>
                        <div>答错</div>
                    </div>
                    <div>
                        <div>{{ dialogInfo && dialogInfo.rightCount }}</div>
                        <div>答对</div>
                    </div>
                    <div>
                        <div>{{ dialogInfo && dialogInfo.totalCount }}</div>
                        <div>答题</div>
                    </div>
                </div>
                <div class="draw" @click="toLottery" :style="`visibility: ${!dialogInfo.lotteryId && 'hidden'}`">
                    恭喜你获得抽奖机会,
                    <span>点击前往抽奖</span>
                </div>
                <div class="dialog-btn">
                    <div @click="goBack">返回首页</div>
                    <!--                    <div @click="toRank">排行榜</div>-->
                </div>
            </div>
        </van-dialog>
        <van-popup v-model="listShow" position="bottom" :style="{ height: '40%' }">
            <div class="questions-title">错题编号</div>
            <div class="questions">
                <div v-for="(item, index) of data"
                     :class="{'focus-box': answerIndex === index}"
                     @click="select(item, index)">{{item.serialNumber}}</div>

            </div>
        </van-popup>
        <div class="Details">
            <nav>
                <div @click="goBack">
                    <img src="../../../assets/images/Answer/arrow.png" alt="">
                </div>
                <div>答案</div>
                <div></div>
            </nav>
            <div class="btn-list" @click="listShow = true">错题查看</div>
            <img class="banner" src="../../../assets/images/Answer/bg-banner.png" alt="">
            <div class="info-box">
                <div class="answer-num">
                    <div>题目数量</div>
                    <div>{{ answerIndex + 1 }}/{{ data[answerIndex] && data.length }}</div>
                </div>
                <van-progress
                        style="width: 90%;margin: 0 auto"
                        :percentage="percentage"
                        pivot-color="rgba(251, 152, 107, 1)"
                        stroke-width="8"
                        color="linear-gradient(to right, rgba(215, 15, 17, 1), rgba(251, 152, 107, 1)"
                />
            </div>
            <div class="answer-box">
                <div>{{ data[answerIndex] && data[answerIndex].questType | typeFilters }}</div>
                <div>{{ data[answerIndex] && data[answerIndex].questContent }}</div>
                <div class="item-box">
<!--                    @click="select(item)"-->

                    <div v-for="(item, index) of data[answerIndex] && data[answerIndex].qaQuestOptionList"
                         :class="{'focus-item':  item.isCorrectAnswer === '1', 'wrong': item.answerConfirm === false && item.isCorrectAnswer !== '1'}"
                         :key="index">
                        <img v-show="item.isCorrectAnswer === '1'"
                             src="https://img.qiluyidian.net/PGTWAp_1670911319237.png?imageslim" alt="">
                        <img v-show="item.answerConfirm === false && item.isCorrectAnswer !== '1'"
                             src="https://img.qiluyidian.net/H7TM8W_1670911886032.png?imageslim" alt="">
                        {{ index | itemFilter }}.{{ item.optionContent }}
                    </div>
                </div>
                <div class="btn-box">
                    <div class="answer-btn" v-show="answerIndex !== 0" @click="answerIndex !== 0 ? nextAnswer(-1) : null">
                        上一题
                    </div>
                    <div class="answer-btn" v-show="answerIndex + 1  <  data.length" @click="answerIndex + 1  <  data.length ? nextAnswer(1) : null">
                        下一题
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import login from '../../../components/login'
    import wxShare from '../../../assets/js/wx-share'
    import {isWeixin, loginWx, WXauthorizeWx} from '../../../assets/js/wx-authorize'
    import {Toast} from "vant";
    import {getFindRecordDto, getQaQuestList, submitQaAnswer} from '../../../assets/api/answer'
    import {formatSeconds} from "../../../assets/js/untils";

    export default {
        name: "Details",
        components: {
            login,
        },
        data() {
            return {
                bannerImg: 'https://img.qiluyidian.net/jJ2P5i_1639029527590.jpg',
                bannerVideo: 'https://img.qiluyidian.net/K2Na8t_1639033504103.mp4',
                data: [],
                show: false,
                id: '',
                answerIndex: 0,
                percentage: 0,
                dialog: false,
                dialogInfo: '',
                listShow: false
                // lotteryId: ''

            }
        },
        created() {
            this.id = this.$route.query.id
            this.getQaQuestList()
        },
        mounted() {
            if (!this.token) {
                if (isWeixin()) {
                    loginWx()
                } else {
                    this.show = false
                }
            }
        },
        methods: {
            toLottery() {
                this.$router.replace({
                    path: '/draw1',
                    query: {
                        lotteryId: this.dialogInfo.lotteryId
                    }
                })
            },
            async getQaQuestList() {
                let {data, code, msg} = await getFindRecordDto({qaId: this.id})
                console.log(data)
                if (code === 200) {
                    // choices
                    // data[0].qaQuestOptionList[0].answerConfirm = true
                    // data[0].qaQuestOptionList[1].answerConfirm = false
                    this.data = data.map(item => {
                        console.log(item)
                        item.qaQuestOptionList.map(option => {
                            item.choices.map(choice => {
                                if(option.optionId === choice) {
                                    option.answerConfirm = false
                                }
                            })
                        })
                        return item
                    })

                    this.percentage = parseInt(((this.answerIndex + 1) / (this.data.length)) * 100)
                } else {
                    msg && this.$message.error(msg)
                }
            },
            select(item, index) {
                this.answerIndex = index
                this.listShow = false
                // let {data, answerIndex} = this
                // if (data[answerIndex].questType !== '2') {
                //     data[answerIndex].qaQuestOptionList.map(item => {
                //         this.$set(item, 'answer', false)
                //         // item.isCorrectAnswer = '0'
                //     })
                // }
                // this.$set(item, 'answer', !item.answer)
            },
            async nextAnswer(num) {
                this.answerIndex += num
                console.log(this.answerIndex)
            },
            goBack() {
                this.$router.back()
            },
            goHome() {

            },
            toRank() {
                this.$router.push({
                    path: '/answers1-rank',
                    query: {
                        id: this.id
                    }
                })
            }

        },
        watch: {
            answerIndex(val) {
                console.log(this.data.length)
                // let index  = this.answerIndex === 0 ? 0 : this.answerIndex + 1
                // let dataLength = this.data.length || 0
                this.percentage = parseInt(((this.answerIndex + 1) / (this.data.length)) * 100)
            }
        },
        computed: {
            theme() {
                return this.$store.getters.getTheme
            },
            token() {
                return this.$store.getters.getToken
            }
        },
        filters: {
            itemFilter(index) {
                let item = ''
                switch (index) {
                    case 0:
                        item = 'A'
                        break
                    case 1:
                        item = 'B'
                        break
                    case 2:
                        item = 'C'
                        break
                    case 3:
                        item = 'D'
                        break
                }
                return item
            },
            typeFilters(type) {
                let name = ''
                switch (type) {
                    case '1':
                        name = '单选'
                        break
                    case '2':
                        name = '多选'
                        break
                    case '3':
                        name = '判断'
                        break
                }
                return name
            },
            formatSeconds
        }

    }
</script>

<style lang="less">
    #Details {
        .van-dialog {
            background-color: rgba(0, 0, 0, 0) !important;
        }

        .van-dialog__footer {
            display: none;
        }
    }
</style>

<style scoped lang="less">
    @import "../../../assets/css/theme";

    .dialog-box {
        width: 320px;
        height: 420px;
        background: url("../../../assets/images/Answer/dialog-bg.png");
        background-size: 100% 100%;
        padding-top: 130px;
        box-sizing: border-box;

        .dialog-rate {
            > span:nth-of-type(1) {
                font-size: 20px;
                color: rgba(51, 51, 51, 1);
                font-weight: bold;
            }

            > span:nth-of-type(2) {
                margin-left: 20px;
                font-size: 22px;
                color: rgba(203, 7, 9, 1);
                font-weight: bold;
            }
        }

        .dialog-time {
            margin-top: 35px;

            > div:nth-of-type(1) {
                font-weight: bold;
            }
        }

        .draw {
            margin: 16px auto;

            > span {
                color: rgb(232, 129, 60);
            }
        }

        .dialog-info {
            width: 90%;
            margin: 20px auto 0;
            display: flex;
            justify-content: space-around;

            > div {
                > div:nth-of-type(1) {
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }

        .dialog-btn {
            width: 80%;
            margin: 0px auto 0;
            display: flex;
            //justify-content: space-between;
            justify-content: center;
            align-items: center;


            > div:nth-of-type(1) {
                width: 120px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid rgb(232, 129, 60);
                border-radius: 20px;
                color: rgb(232, 129, 60);
            }

            > div:nth-of-type(2) {
                width: 120px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid rgb(232, 129, 60);
                border-radius: 20px;
                background-color: rgb(232, 129, 60);
                color: white;
            }
        }
    }

    .module-theme(@theme, @tag) {
        .Details {
            max-width: 375px;
            margin: auto;
            //min-height: 885px;
            //padding-bottom: 126px;
            background-image: url("../../../assets/images/VoteTemp-1/bg.png");
            background-size: 100% auto;
            background-repeat: no-repeat;
            position: relative;

            .btn-list {
                position: absolute;
                right: 0;
                top: 300px;
                width: 66px;
                height: 25px;
                border-radius: 50px 0 0 50px;
                background: #D70F11;
                color: white;
                font-size: 12px;
                text-align: center;
                line-height: 25px;
            }


            nav {
                width: 375px;
                height: 45px;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: white;

                > div {
                    width: 15%;
                    text-align: center;
                }
            }

            .banner {
                width: 375px;
                display: block;
            }

            .info-box {
                width: 100%;
                margin: 0 auto 10px;
                background: white;
                padding-bottom: 16px;

                .answer-num {
                    width: 90%;
                    margin: 0 auto;
                    display: flex;
                    justify-content: space-between;
                    margin: 0 auto;
                    padding: 5px 0 16px;
                    box-sizing: border-box;

                    > div:nth-of-type(1) {
                        font-size: 14px;
                    }

                    > div:nth-of-type(2) {
                        font-weight: bold;
                    }
                }
            }

            .answer-box {
                margin: 0 auto;
                width: 355px;
                min-height: 490px;
                background: #FFFFFF;
                border-radius: 5px;
                padding-top: 15px;

                > div:nth-of-type(1) {
                    padding: 0 15px;
                    box-sizing: border-box;
                    text-align: left;
                    font-size: 14px;
                    color: rgba(102, 102, 102, 1);
                    margin: 10px 0;
                }

                > div:nth-of-type(2) {
                    padding: 0 15px;
                    box-sizing: border-box;
                    text-align: left;
                    font-size: 16px;
                    color: rgba(51, 51, 51, 1);
                    font-weight: bold;
                    word-break: break-all;
                }

                .item-box {
                    font-size: 15px;

                    > div {
                        //padding: 10px 0;
                        width: 315px;
                        //min-height: 50px;
                        background: #F9F9F9;
                        border-radius: 4px;
                        margin: 12px auto;
                        line-height: 24px;
                        display: flex;
                        padding: 10px 5px 10px 20px;
                        box-sizing: border-box;
                        justify-content: flex-start;
                        text-align: left;
                        word-break: break-all;
                        position: relative;

                        > img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 30px;
                            height: 30px;
                        }

                    }

                    .focus-item {
                        background: #FDEEE0;
                        color: rgba(255, 123, 0, 1);
                    }

                    .wrong {
                        background: #FFEDEA;
                        color: #D70F11;
                    }
                }

                .btn-box {
                    width: 329px;
                    height: 45px;
                    display: flex;
                    justify-content: space-around;
                    margin: 50px auto 0;

                    > .answer-btn {
                        width: 153px;
                        height: 45px;
                        line-height: 45px;
                        background-color: rgb(232, 129, 60);
                        color: white;
                        border-radius: 50px;

                        &:nth-of-type(1) {
                            color: rgb(232, 129, 60);
                            background-color: white;
                            border: 1px solid rgb(232, 129, 60);
                        }
                    }
                }

                /*.answer-btn {*/
                /*    width: 329px;*/
                /*    height: 45px;*/
                /*    line-height: 45px;*/
                /*    background-color: rgb(232, 129, 60);*/
                /*    color: white;*/
                /*    border-radius: 50px;*/
                /*    margin: 0 auto;*/
                /*}*/
            }
        }

        .questions-title {
            font-size: 18px;
            font-weight: bold;
            width: 90%;
            margin: 0 auto;
            padding: 16px 0 7px;
            border-bottom: 1px solid rgba(255, 255, 255, .5);
        }

        .questions {
            /*margin-top: 14px a;*/
            width: 300px;
            display: flex;
            margin: 14px auto;
            flex-wrap: wrap;

            > div {
                width: 40px;
                height: 40px;
                border-radius: 8px;
                border: 1px solid #D1D1D1;
                /*margin-right: 8px;*/
                /*margin-left: 8px;*/
                margin: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .focus-box {
        background: #FFEDEA;
        color: #D70F11;
        border: 1px solid #D70F11!important;
    }
</style>
