<template>
    <div id="Rank" :class="theme || 'theme-red-CB0709'">
        <div class="content Rank">
            <nav>
                <div @click="goBack">
                    <img src="../../../assets/images/Answer/arrow.png" alt="">
                </div>
                <div>排行榜</div>
                <div></div>
            </nav>
            <!--            <img class="banner" src="../../../assets/images/VoteTemp-1/banner.png" alt="">-->
            <img class="banner" src="../../../assets/images/Answer/rank.png" alt="">
            <div class="content-box">
                <div class="box-title">排行榜</div>
                <div class="rank-box">
                    <div class="rank-item" v-for="(item, index) of list" :key="index">
                        <div v-html="rankHandle(index)"></div>
                        <img :src="item.headImg" alt="">
                        <div>{{ item.name }}</div>
                        <div>{{ parseInt(item.accuracy) }}%</div>
                    </div>
                </div>
                <div class="more-btn" @click="getMore">加载更多</div>
            </div>
        </div>
    </div>
</template>

<script>
import {getQaRank} from '../../../assets/api/answer'
export default {
    name: "Rank",
    data() {
        return {
            id: '',
            form: {
                pageNo: 1,
                pageSize: 10
            },
            list: [],
            flag: true
        }
    },
    methods: {
        goBack() {
            this.$router.back()
        },
        getParams() {
            let {id} = this.$route.query
            this.id = id
            this.getQaRank()
        },
        async getQaRank() {
            if(!this.flag) {
                this.$message.error('已经没有更多数据了')
                return false
            }

            let {code, msg, data} = await getQaRank({
                ...this.form,
                qaId: this.id
            })
            if(code === 200) {
                this.list = this.list.concat(data)
            }else {
                this.flag = false
                this.$message.error('已经没有更多数据了')
            }
        },
        getMore(){
            this.form.pageNo ++
            this.getQaRank()
        },
        rankHandle(index) {
            let imgArr = [
                require('../../../assets/images/Answer/rank-1.png'),
                require('../../../assets/images/Answer/rank-2.png'),
                require('../../../assets/images/Answer/rank-3.png')
            ]
            let rankNum = index + 1
            let html = ''
            if (rankNum <= 3) {
                html = `<img src="${imgArr[index]}" alt="">`
            } else {
                html = `<span style="color: rgba(203, 7, 9, 1)">${rankNum}</span>`
            }
            return html
        }
    },
    created() {
        this.getParams()
    },
    computed: {
        theme() {
            return this.$store.getters.getTheme
        },
        token() {
            return this.$store.getters.getToken
        }
    },
}
</script>

<style scoped lang="less">
@import "../../../assets/css/theme";

.module-theme(@theme, @tag) {
    .Rank {
        max-width: 375px;
        margin: auto;
        min-height: 885px;
        padding-bottom: 126px;
        background-image: url("../../../assets/images/VoteTemp-1/bg.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        position: relative;


        nav {
            width: 375px;
            height: 45px;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: white;

            > div {
                width: 15%;
                text-align: center;
            }
        }

        .banner {
            width: 375px;
        }

        .content-box {
            width: 355px;
            margin: 16px auto;
            background: white;


            .box-title {
                background: url("../../../assets/images/Answer/box-title.png");
                background-size: 100% 100%;
                width: 220px;
                height: 45px;
                line-height: 40px;
                font-size: 18px;
                color: @theme;
                font-weight: bold;
                margin: 0 auto;
            }

            .box-text {
                width: 90%;
                margin: 20px auto;
            }

            .rank-item {
                width: 100%;
                padding: 0 20px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 40px;
                margin: 10px 0;

                > div:nth-of-type(1) {
                    width: 30px;
                    height: 30px;
                }

                > img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    overflow: hidden;
                }

                > div:nth-of-type(2) {
                    font-size: 14px;
                    width: 150px;
                }

                > div:nth-of-type(3) {
                    width: 50px;
                    font-size: 16px;
                    color: #E8813C;
                }
            }
        }

        .more-btn {
            border-radius: 5px;
            border: 1px solid @theme;
            color: @theme;
            font-size: 12px;
            padding: 2px 4px;
            width: 120px;
            margin: 0 auto;
        }

    }
}
</style>