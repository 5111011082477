import qs from 'qs'
import axios from "axios";
// import store from "../store";
let imgDomain = 'https://img.qiluyidian.net/'
const info = {
    imgDomain
}
const service = {
    // baseURL: '/api', //  測試代理
    // baseURL: 'http://cms.ql1w.com:8071/gateway', // 微服务api 的 base_url 測試
    baseURL: 'https://gateway.qiluyidian.net/gateway', // 微服务  正式环境
    timeout: 30000, // request timeout
    headers: {'Content-Type': 'application/json;charset=UTF-8'}
}
export default function request (req) {
    if (req.url) {
        let config = Object.assign({}, service, req)
        if (!config.params) {
            config.params = {}
        }
        config.params.timestamp = new Date().getTime()
        return new Promise((resole, reject) => {
            axios.request(config).then(res => {
                if (res.status !== 200) {
                    reject(res)
                } else {
                    resole(res)
                }
            }).catch(err => {
                // console.log(err)
                // console.log(err.request)
                // console.log(err.request.readyState)
                // console.log(err.request.status)
                // if(err.request.readyState == 4 && err.request.status == 0){
                //   //我在这里重新请求
                // }
                reject(err)
            })
        })
    }
}


