import {Toast} from 'vant'
import axios from "axios";
import {ydLogin} from '../api/user'
import store from '../../store/index'
import qs from "qs";
// 微信配置
const config = {
    AppSecret: 'ee7cc693908469ba95fd465a6ebd87dd',
    coverSuffix: '-newslist7.0.4', // "-newslistnormal61", -smallimage3G
    coverBigSuffix: '', // "-newslistbig6", -bigimage4G
    AppID: "wxb169a167398bbf8f",
    wsStatisUrl: 'https://wscdn.ql1d.com',
    isnext: true
}

// 判断是否微信浏览器  授权登录
function isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}

function getIsApp() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/qiluyidian/i) == "qiluyidian") {
        return true;
    }
    return false;
}

function loginWx() {
    console.log('qweyqwiyeqwdbl12l')
    const CODE = GetQueryString("code");

    // const CODE = GetQueryString("code");
    const STATE = GetQueryString("state");
    // debugger
    console.log(CODE)
    console.log(STATE)
    // debugger
    if (STATE !== null) {
        if (CODE === null) { // 拒绝授权
            // clearCookie('token');
            localStorage.removeItem("token");
            WXauthorizeWx();
        } else { // 此页面为微信重定向过来且用户允许授权
            console.log(111)
            config.isnext = false;
            let params = {
                "code": CODE,
            };
            console.log(store.state)
            let url = store.state.user.authType == 2 ? '/activity/wechatLogin' : '/activity/qlydLogin'
            console.log(url)
            ydLogin(params, url).then(res => {
                console.log(res)
                let {code, data, msg} = res
                if(code == 200) {
                    localStorage.setItem("token", data);  // 壹点token
                    store.commit('CHANGE_TOKEN', data);
                }else if(code == 515) {
                    console.log(store.getters.getBindInfo)
                    if(!store.getters.getOpenId) {
                        store.commit('SET_BIND_INFO', data)
                        store.commit('OPEN_DIALOG')
                    }else {
                        store.commit('OPEN_DIALOG')
                    }

                }else if(code == 500) {
                    console.log(111111)
                    config.isnext = true
                    WXauthorizeWx()

                }else {
                    if(store.getters.getOpenId) {
                        store.commit('OPEN_DIALOG')
                    }
                    localStorage.clear()
                }

            })
            // axios.post('https://gateway.qiluyidian.net/gateway/auth/wechat/login', {
            //     "openId": 'oxacOOjQGbQcb5_l2Yu3ds8EoGJk',
            //     "accessToken": '51_HdEMJY7qvRaIiqo1uBl2OxHUNLBAWspjnPt4t7muXgD4JOymI9Z1087YkS4CqMarD383-bZOwpwsMQBKwx_X-Q'  // 微信token
            // }).then(re => {
            //     if (re.data.code == 200) {
            //         let tokenInfo = re.data.data;
            //         // 将token存到localStorage中  用于对后台接口的请求
            //         // setCookie('token', tokenInfo.token, 7);
            //         localStorage.setItem("token", tokenInfo.token);  // 壹点token
            //         store.commit('CHANGE_TOKEN', res.data.data.access_token);
            //         // toast(localStorage.getItem("token"), 5000);
            //         // localStorage.setItem("hasImprove", tokenInfo.hasImprove);
            //         window.location.href = window.location.href.split('?')[0];
            //     } else {
            //         Toast('授权失败');
            //         config.isnext = true;
            //     }
            // });
            //获取access_token

            // axios.post('https://api.qiluyidian.mobi/qlwb/getAccessToken.do', {
            //     params
            // }).then(res => {
            //     console.log(res, "fabhui")
            //     // if (res.data.code == 200) {
            //     //     let accessInfo = res.data.data;
            //     //     // setCookie('openId', accessInfo.openId, 8);
            //     //     // 存到cookie中   用于记录并判断微信授权是否过期
            //     //     // setCookie('wxToken', accessInfo.accessToken, 7);
            //     //     // localStorage.setItem("wxToken", accessInfo.accessToken)
            //     //     //获取token
            //     //     let params1 = {
            //     //         "openId": accessInfo.openId,
            //     //         "accessToken": accessInfo.accessToken  // 微信token
            //     //     };
            //     //     if (accessInfo.openId) {
            //     //         //getToken
            //     //         axios.post('https://gateway.qiluyidian.net/gateway/auth/wechat/login', {
            //     //             params1
            //     //         }).then(re => {
            //     //             if (re.data.code == 200) {
            //     //                 let tokenInfo = re.data.data;
            //     //                 // 将token存到localStorage中  用于对后台接口的请求
            //     //                 // setCookie('token', tokenInfo.token, 7);
            //     //                 localStorage.setItem("token", tokenInfo.token);  // 壹点token
            //     //                 store.commit('CHANGE_TOKEN', res.data.data.access_token);
            //     //                 // toast(localStorage.getItem("token"), 5000);
            //     //                 // localStorage.setItem("hasImprove", tokenInfo.hasImprove);
            //     //                 window.location.href = window.location.href.split('?')[0];
            //     //             } else {
            //     //                 Toast('授权失败');
            //     //                 config.isnext = true;
            //     //             }
            //     //         });
            //     //     }
            //     // } else {
            //     //     Toast('授权失败');
            //     //     config.isnext = true;
            //     // }
            //
            // })
        }
    }
    else {
        WXauthorizeWx()
    }
};

// 微信授权登录  返回的code拼接到自己的url上
function WXauthorizeWx() {
    // debugger
    if (isWeixin() && config.isnext) {
        if (!localStorage.getItem('token')) {
            const origin_state = Math.floor(Math.random() * 1000000);
            const SCOPE = "snsapi_userinfo";
            let currentURL = window.location.href;
            let [params, query] = currentURL.split('?')
            query = qs.parse(query)
            delete query.code
            delete query.state
            console.log(qs.stringify(query))
            // console.log(params + '?' + qs.stringify(query))
            currentURL = params + '?' + qs.stringify(query)
            config.isnext = false;
            let arr = currentURL.split('#')
            let url = 'https://m.ql1d.com/preview/transfer.html?url=' + arr[0] + 'substring' + arr[1]
            console.log(url)
            // debugger
            // debugger
            // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + config.AppID + '&redirect_uri=' + encodeURIComponent(currentURL) + '&response_type=code&scope=' + SCOPE + '&state=' + origin_state + '#wechat_redirect'

            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + config.AppID + '&redirect_uri=' + encodeURIComponent(url) + '&response_type=code&scope=' + SCOPE + '&state=STATE' + '#wechat_redirect'
        }
    }
}

// 获取地址栏中的字符串
function GetQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r = window.location.href.substr(1).match(reg);
    console.log(window.location)
    if (r != null) return unescape(r[2]);
    return r
};

// function toast(msg, duration) {
//     duration = isNaN(duration) ? 3000 : duration;
//     var m = document.createElement('div');
//     m.innerHTML = msg;
//     m.style.cssText = "max-width:80%;min-width: 150px;padding:5px 14px;min-height: 22px;color: rgb(255, 255, 255);line-height: 22px;text-align: center;border-radius: 4px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 999999;background: rgba(0, 0, 0,.7);font-size: 14px;";
//     document.body.appendChild(m);
//     setTimeout(function () {
//         var d = 0.5;
//         m.style.webkitTransition = '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in';
//         m.style.opacity = '0';
//         setTimeout(function () {
//             document.body.removeChild(m)
//         }, d * 1000);
//     }, duration);
// };

export {
    WXauthorizeWx,
    isWeixin,
    loginWx,
    getIsApp
}
